import { useEffect, useState } from "react";
import { Row, Button, Col, Table, notification, Popconfirm } from "antd";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { POST } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearch,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";
import notificationErrors from "../../../../providers/notificationErrors";
import ModalForm from "./ModalForm";

export default function PageUser(props) {
	const {
		tableFilter,
		setTableFilter,
		selectedRowKeys,
		setSelectedRowKeys,
		dataUser,
		refetchSourceUser,
	} = props;

	const location = useLocation();

	const [toggleModalForm, setToggleModalForm] = useState({
		open: false,
		data: null,
	});

	useEffect(() => {
		if (dataUser) {
			refetchSourceUser();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const { mutate: mutateDeleteUser, isLoading: isLoadingArchivedUser } = POST(
		`api/multiple_archived_user`,
		"users_list"
	);

	const handleSelectedArchived = (isTrash) => {
		let data = {
			ids: selectedRowKeys,
		};
		mutateDeleteUser(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "User",
						description: res.message,
					});
				} else {
					notification.error({
						message: "User",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useTableScrollOnTop("tblUser", location);

	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Button
					type="primary"
					className=" btn-main-primary btn-main-invert-outline b-r-none mr-10"
					name="btn_add"
					icon={<FontAwesomeIcon icon={faPlus} />}
					onClick={() =>
						setToggleModalForm({
							open: true,
							data: null,
						})
					}
				>
					Add User
				</Button>

				<Button
					className={`btn-main-primary min-w-150 mr-10 ${
						tableFilter.isTrash === 0 ? "active" : "outlined"
					}`}
					onClick={() => {
						setTableFilter((ps) => ({
							...ps,
							isTrash: 0,
							status: "Active",
						}));
						setSelectedRowKeys([]);
					}}
				>
					Active
				</Button>

				<Button
					className={`btn-main-primary min-w-150 ${
						tableFilter.isTrash === 1 ? "active" : "outlined"
					}`}
					onClick={() => {
						setTableFilter((ps) => ({
							...ps,
							isTrash: 1,
							status: "Archived",
						}));
						setSelectedRowKeys([]);
					}}
				>
					Archived
				</Button>

				{selectedRowKeys.length > 0 && (
					<Popconfirm
						title={
							<>
								Are you sure you want to
								<br />
								{tableFilter.isTrash === 1 ? "activate" : "archive"} the
								selected <br />
								{selectedRowKeys.length > 1 ? "users" : "user"}?
							</>
						}
						okText="Yes"
						cancelText="No"
						onConfirm={() => {
							handleSelectedArchived(
								tableFilter.isTrash === 1 ? "Active" : "Archived"
							);
						}}
					>
						<Button
							className="btn-main-secondary ml-10"
							name="btn_active_archive"
							loading={isLoadingArchivedUser}
						>
							{tableFilter.isTrash === 1 ? "ACTIVE" : "ARCHIVED"} SELECTED
						</Button>
					</Popconfirm>
				)}
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={12}>
				<Row gutter={[12, 12]}>
					<Col xs={24} sm={24} md={24}>
						<div className="tbl-top-filter">
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
							<TableGlobalSearch
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
					</Col>

					<Col xs={24} sm={24} md={24}>
						<Table
							id="tblUser"
							className="ant-table-default ant-table-striped"
							dataSource={dataUser && dataUser.data.data}
							rowKey={(record) => record.id}
							pagination={false}
							bordered={false}
							scroll={{ x: "max-content" }}
							sticky={{
								offsetHeader: 0,
							}}
							rowSelection={{
								selectedRowKeys,
								onChange: (selectedRowKeys) => {
									setSelectedRowKeys(selectedRowKeys);
								},
							}}
						>
							<Table.Column
								title="Action"
								key="action"
								dataIndex="action"
								align="center"
								width={150}
								render={(text, record) => {
									return (
										<>
											<Button
												type="link"
												className="color-1"
												name="btn_edit"
												onClick={() =>
													setToggleModalForm({
														open: true,
														data: record,
													})
												}
											>
												<FontAwesomeIcon icon={faPencil} />
											</Button>
										</>
									);
								}}
							/>

							<Table.Column
								title="User Name"
								key="username"
								dataIndex="username"
								sorter={(a, b) => a.username.localeCompare(b.username)}
								defaultSortOrder="ascend"
							/>

							<Table.Column
								title="Email"
								key="email"
								dataIndex="email"
								sorter={(a, b) => a.email.localeCompare(b.email)}
								defaultSortOrder="ascend"
							/>

							<Table.Column
								title="User Role"
								key="user_role_id"
								dataIndex="user_role_id"
								sorter={(a, b) => a.user_role_id - b.user_role_id}
								defaultSortOrder="ascend"
								width={150}
							/>
						</Table>
					</Col>

					<Col xs={24} sm={24} md={24}>
						<div className="tbl-bottom-filter">
							<TableShowingEntriesV2 />
							<TablePagination
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
								setPaginationTotal={dataUser?.data.total}
								showLessItems={true}
								showSizeChanger={false}
								tblIdWrapper="tbl_wrapper"
							/>
						</div>
					</Col>
				</Row>
			</Col>

			<ModalForm
				toggleModalForm={toggleModalForm}
				setToggleModalForm={setToggleModalForm}
				// dataUserRole={dataUserRole && dataUserRole.data}
			/>
		</Row>
	);
}
