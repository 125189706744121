import { Button, Col, Flex, Layout, Row } from "antd";
import React, { useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCreditCardFront,
	faMoneyCheckDollar,
} from "@fortawesome/pro-regular-svg-icons";
import ModalPaymentOTC from "./components/ModalPaymentOTC";

let isLoggedIn = localStorage.getItem("token");

export default function PagePayment() {
	const navigate = useNavigate();
	const params = useParams();

	const [toggleModalPaymentOTC, setToggleModalPaymentOTC] = useState({
		open: false,
		data: null,
	});

	if (isLoggedIn) {
		return (
			<>
				<Layout
					id="PagePayment"
					style={{
						minHeight: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Flex
						justify="flex-end"
						align="flex-end"
						style={{ position: "absolute", top: 0, right: 0 }}
					>
						<img
							width="400px"
							src="/assets/images/fsuu_logo_v2.png"
							alt="fsuu-logo"
						/>
					</Flex>

					<Row gutter={[0, 12]} style={{ width: "100%" }}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Flex justify="center">
								<Flex vertical gap={50}>
									<Flex vertical>
										<Button
											className="btn draw-border br-0 glass"
											// onClick={() => navigate("/profiling-portal/edit")}
											onClick={() =>
												setToggleModalPaymentOTC({
													open: true,
													// data: record,
												})
											}
										>
											<Flex justify="center" vertical gap={10}>
												<FontAwesomeIcon
													icon={faMoneyCheckDollar}
													style={{ fontSize: "50px" }}
												/>
												<span>
													Over-the-Counter <br />
													(OTC) Payment
												</span>
											</Flex>
										</Button>
									</Flex>

									<Flex vertical>
										<Button
											className="btn draw-border br-0"
											onClick={() =>
												navigate(`/payment-qrscan/online/${params.qrcode}`)
											}
										>
											<Flex justify="center" vertical gap={10}>
												<FontAwesomeIcon
													icon={faCreditCardFront}
													style={{ fontSize: "50px" }}
												/>
												<span>Online Payment</span>
											</Flex>
										</Button>
									</Flex>
								</Flex>
							</Flex>
						</Col>
					</Row>
				</Layout>

				<ModalPaymentOTC
					toggleModalPaymentOTC={toggleModalPaymentOTC}
					setToggleModalPaymentOTC={setToggleModalPaymentOTC}
				/>
			</>
		);
	} else {
		return <Navigate to="/" />;
	}
}
