import { Modal, Button, Typography, Flex, Image, QRCode } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export default function ModalProfile(props) {
	const { toggleModalProfile, qrCode } = props;

	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Modal
			className="modal-profiling-portal"
			open={toggleModalProfile.open}
			forceRender
			footer={[
				<Flex justify="center" key="footer">
					<Button
						className="btn-main-primary"
						type="primary"
						onClick={() => navigate("/profiling-portal")}
					>
						Done
					</Button>
				</Flex>,
			]}
		>
			<Image.PreviewGroup>
				<Flex className="mt-30" vertical>
					<img
						src={
							location.pathname.includes("/profiling-portal/add")
								? "./../assets/images/success_astronaut.gif"
								: "./../../assets/images/success_astronaut.gif"
						}
						alt="fsuu_logo"
						width={150}
						crossOrigin="anonymous"
					/>
				</Flex>
			</Image.PreviewGroup>

			<Typography.Title level={4} color="blue">
				Your application has been successfully submitted!
			</Typography.Title>

			{location.pathname.includes("/profiling-portal/add") ? (
				qrCode && (
					<div
						id="myqrcode"
						style={{
							overflow: "hidden",
						}}
					>
						<QRCode
							errorLevel="H"
							value={`${window.location.origin}/payment-qrscan/${qrCode}`}
							bgColor="#ffffff"
							icon="/assets/images/logo.png"
							size={200}
							type="svg"
						/>
					</div>
				)
			) : (
				<div
					id="myqrcode"
					style={{
						overflow: "hidden",
					}}
				>
					<QRCode
						errorLevel="H"
						value={`${window.location.origin}/payment-qrscan/${toggleModalProfile.qrCode}`}
						bgColor="#ffffff"
						icon="/assets/images/logo.png"
						size={200}
						type="svg"
					/>
				</div>
			)}

			<p style={{ textAlign: "center", fontSize: "13px" }}>
				<strong>Note</strong>: The QR code will be sent to your email along with
				further instructions.
			</p>

			<em style={{ textAlign: "center", fontSize: "14px" }}>
				Please take note of your username, "
				<strong style={{ fontSize: "16px" }}>
					{toggleModalProfile.username}
				</strong>
				" to verify and update your information for future exam scheduling. You
				may now proceed to profile verification. Thank you!
				<br />
			</em>
		</Modal>
	);
}
