import { Row, Col, Form, Typography, Button, Flex } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import dayjs from "dayjs";
import { GET } from "../../../providers/useAxiosQuery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import ModalDataConsent from "./components/ModalDataConsent";
import PageApplicantList from "./PageApplicantList";
import PreferredExamCalendar from "./components/PreferredExamCalendar";

export default function PageApplicant(props) {
	const { refetchPermissions } = props;

	const [form] = Form.useForm();
	const location = useLocation();

	const [examSchedule, setExamSchedule] = useState();
	const [selectExamSchedule, setSelectExamSchedule] = useState();

	const [toggleModalDataConsent, setToggleModalDataConsent] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		status: "Active",
		from: location.pathname,
	});

	useEffect(() => {
		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			sort_field: "created_at",
			sort_order: "desc",
			status:
				location.pathname === "/aplicants-schedule/walk-in"
					? "Active"
					: "Deactivated",
			from: location.pathname,
		});

		return () => {};
	}, [location]);

	const {
		data: dataExamScheduleSource,
		refetch: refetchExamScheduleSource,
	} = GET(
		`api/exam_schedule?${new URLSearchParams(tableFilter)}`,
		"exam_schedule_list",
		(res) => {
			refetchPermissions();
		}
	);

	useEffect(() => {
		refetchExamScheduleSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	useEffect(() => {
		const setExamDate = dataExamScheduleSource?.data?.data?.find(
			(item) => item.id === examSchedule
		);

		setSelectExamSchedule(setExamDate);
	}, [examSchedule, dataExamScheduleSource]);

	return (
		<Form form={form}>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={12}>
					<Form.Item name="exam_schedule_id">
						<PreferredExamCalendar
							form={form}
							dataExamScheduleSource={dataExamScheduleSource}
							setExamSchedule={setExamSchedule}
							selectExamSchedule={selectExamSchedule}
						/>
					</Form.Item>
				</Col>

				{/* <Col xs={24} sm={24} md={24} lg={24}>
					<Flex gap={20} align="center">
						<Button
							type="primary"
							icon={<FontAwesomeIcon icon={faPlus} />}
							// onClick={() => navigate(`${location.pathname}/add`)}
							onClick={() =>
								setToggleModalDataConsent({
									open: true,
								})
							}
							name="btn_add"
						>
							Add Walk-In Applicant
						</Button>

						{selectExamSchedule && (
							<Typography.Title level={5} className="mt-0">
								<span style={{ fontWeight: "normal" }}>Date:</span>{" "}
								<span className="pr-20" style={{ color: "#447ce2" }}>
									{dayjs(selectExamSchedule.exam_date).format("MMMM DD, YYYY")}
								</span>
								<span style={{ fontWeight: "normal" }}>Time:</span>{" "}
								<span className="pr-20" style={{ color: "#447ce2" }}>
									{selectExamSchedule.time_in}{" "}
									{selectExamSchedule.time_in_meridiem} -{" "}
									{selectExamSchedule.time_out}{" "}
									{selectExamSchedule.time_out_meridiem}
								</span>
								<span style={{ fontWeight: "normal" }}>Available Slot/s:</span>{" "}
								<span className="pr-20" style={{ color: "#447ce2" }}>
									{selectExamSchedule.total_available_slot}
								</span>
							</Typography.Title>
						)}
					</Flex>
				</Col> */}

				<PageApplicantList
					dataExamScheduleSource={dataExamScheduleSource}
					selectExamSchedule={selectExamSchedule}
				/>

				<ModalDataConsent
					toggleModalDataConsent={toggleModalDataConsent}
					setToggleModalDataConsent={setToggleModalDataConsent}
				/>
			</Row>
		</Form>
	);
}
