import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Col,
	Row,
	Form,
	Typography,
	Radio,
	Flex,
	Divider,
	Card,
	Steps,
	Carousel,
	Alert,
} from "antd";

import dayjs from "dayjs";
import { GET } from "../../../providers/useAxiosQuery";

import FloatInput from "../../../providers/FloatInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";

export default function PagePaymentOnline() {
	const params = useParams();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

	const [current, setCurrent] = useState(0);
	const carouselRef = useRef(null);

	const onChange = (value) => {
		setCurrent(value);
		if (carouselRef.current) {
			carouselRef.current.goTo(value);
		}
	};

	const afterChange = (currentSlide) => {
		setCurrent(currentSlide);
	};

	const PrevArrow = (props) => {
		const { className, style, onClick } = props;
		return (
			<div
				className={className}
				style={{ ...style, display: "block", left: "-25px" }}
				onClick={onClick}
			>
				<FontAwesomeIcon icon={faArrowLeft} />
			</div>
		);
	};

	const NextArrow = (props) => {
		const { className, style, onClick } = props;
		return (
			<div
				className={className}
				style={{ ...style, display: "block", right: "-25px" }}
				onClick={onClick}
			>
				<FontAwesomeIcon icon={faArrowRight} />
			</div>
		);
	};

	const contentStyle = {
		margin: 0,
		height: "600px",
		color: "#fff",
		lineHeight: "160px",
		textAlign: "center",
		background: "#364d79",
	};

	// console.log("selectedPaymentMethod: ", selectedPaymentMethod);

	const { data: dataStudentExamQr } = GET(
		`api/student_exams`,
		"exam_schedule_list",
		(res) => {},
		false
	);

	let applicantData = {
		fullname: "",
		personal_email: "",
		contact_number: "",
		exam_fee: "",
		exam_date: "",
	};

	if (dataStudentExamQr && dataStudentExamQr.data) {
		const matchedItem = dataStudentExamQr.data.find(
			(item) => item.qr_code === params.qrcode
		);

		if (matchedItem) {
			applicantData = {
				fullname: matchedItem.fullname,
				personal_email: matchedItem.personal_email,
				contact_number: matchedItem.contact_number,
				exam_fee: matchedItem.exam_fee,
				exam_date: matchedItem.exam_date,
			};
		}
	}

	useEffect(() => {
		form.setFieldsValue(applicantData);
	}, [applicantData]);

	const handlePaymentMethodChange = (e) => {
		setSelectedPaymentMethod(e.target.value);
	};

	return (
		<Form form={form} id="PagePaymentOnline">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Typography.Title level={1} style={{ color: "#1c53a8" }}>
						Online Payment
					</Typography.Title>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Typography.Title level={4} className="mt-0">
							Applicant Information
						</Typography.Title>

						<Flex>
							<Col xs={24} sm={24} md={24} lg={8} xl={8}>
								<Form.Item name="fullname">
									<FloatInput
										label="Fullname"
										placeholder="Fullname"
										value={applicantData.fullname}
										required
										readOnly
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24} lg={8} xl={8}>
								<Form.Item name="personal_email">
									<FloatInput
										label="Email"
										placeholder="Email"
										value={applicantData.personal_email}
										required
										readOnly
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24} lg={8} xl={8}>
								<Form.Item name="contact_number">
									<FloatInput
										label="Phone Number"
										placeholder="Phone Number"
										value={applicantData.contact_number}
										required
										readOnly
									/>
								</Form.Item>
							</Col>
						</Flex>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Typography.Title level={4}>Payment</Typography.Title>
						<Flex
							align="center"
							vertical
							style={{
								background: "#f2f1f1",
								borderRadius: "10px",
								padding: "30px",
							}}
						>
							<Typography.Text style={{ fontSize: "18px", color: "#000" }}>
								Your total payment
							</Typography.Text>
							<Typography.Title style={{ margin: "0", color: "#000" }}>
								Php {applicantData.exam_fee}.00
							</Typography.Title>
							<Typography.Text style={{ fontSize: "16px", color: "grey" }}>
								Pay before{" "}
								{dayjs(applicantData.exam_date).format("MMMM DD, YYYY")}
							</Typography.Text>
						</Flex>
					</Col>

					{/* Select Method */}
					<Typography.Title level={4}>Select Method</Typography.Title>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Card className="select-method-card">
							<Flex>
								<Col
									className="radio-option"
									xs={24}
									sm={24}
									md={6}
									lg={6}
									xl={6}
								>
									<Radio.Group defaultValue={selectedPaymentMethod}>
										<Divider orientation="left">E-Wallet</Divider>
										<Radio.Button
											value="GCash"
											className="payment-options"
											onChange={handlePaymentMethodChange}
										>
											<img
												src="/assets/images/gcash_logo.png"
												alt="GCash"
												style={{ maxWidth: "100%", maxHeight: "100%" }}
											/>
										</Radio.Button>

										<Divider orientation="left">Bank Transfer</Divider>
										<Flex gap={10}>
											<Radio.Button
												value="EastWest"
												className="payment-options"
												onChange={handlePaymentMethodChange}
											>
												<img
													src="/assets/images/eastwest_logo.png"
													alt="EastWest"
													style={{ maxWidth: "100%", maxHeight: "100%" }}
												/>
											</Radio.Button>
										</Flex>
									</Radio.Group>
								</Col>

								<Form.Item shouldUpdate noStyle>
									{() => {
										if (selectedPaymentMethod === "GCash") {
											return <>Gcash</>;
										} else if (selectedPaymentMethod === "EastWest") {
											return (
												<>
													{/* Step by Step Bank Transfer */}
													<Col
														className="bank-transfer-method"
														xs={24}
														sm={24}
														md={18}
														lg={18}
														xl={18}
													>
														<Flex className="bank-transfer-instruction">
															<Col
																xs={24}
																sm={24}
																md={14}
																lg={14}
																xl={14}
																className="instruction-steps"
															>
																<Alert
																	message="Note"
																	description={
																		<>
																			A transaction fee of <b>PHP 15</b> will be
																			charged for each transfer. Ensure that the
																			amount you send matches your exact exam
																			fee. Don't forget to upload your proof of
																			payment using the <b>Upload</b> button
																			provided.
																		</>
																	}
																	type="warning"
																	showIcon
																/>

																<Divider />
																<Steps
																	current={current}
																	onChange={onChange}
																	direction="vertical"
																	items={[
																		{
																			title: (
																				<span
																					style={{
																						fontWeight: "bold",
																						color: "#447ce2",
																					}}
																				>
																					Open GCash and Access the Transfer
																					Option
																				</span>
																			),
																			description:
																				"Open the GCash app on your device and select the 'Transfer' option from the dashboard.",
																		},
																		{
																			title: (
																				<span
																					style={{
																						fontWeight: "bold",
																						color: "#447ce2",
																					}}
																				>
																					Choose a Bank
																				</span>
																			),
																			description:
																				"Pick a bank from your saved accounts or browse through the list of partner banks.",
																		},
																		{
																			title: (
																				<span
																					style={{
																						fontWeight: "bold",
																						color: "#447ce2",
																					}}
																				>
																					Input Required Details
																				</span>
																			),
																			description:
																				"Fill in the necessary information and proceed by tapping 'Send Money'.",
																		},
																		{
																			title: (
																				<span
																					style={{
																						fontWeight: "bold",
																						color: "#447ce2",
																					}}
																				>
																					Verify Information
																				</span>
																			),
																			description:
																				"Double-check all the details before clicking on 'Confirm'.",
																		},
																		{
																			title: (
																				<span
																					style={{
																						fontWeight: "bold",
																						color: "#447ce2",
																					}}
																				>
																					Confirmation and Receipt
																				</span>
																			),
																			description:
																				"View, download, or share your receipt. We will also send you a confirmation via email.",
																		},
																	]}
																/>
																<Divider />
																<Typography.Title
																	level={3}
																	style={{ color: "#1c53a8" }}
																>
																	Please upload your proof of payment on the
																	link provided
																</Typography.Title>
																<Flex gap={20}>
																	<Col style={{ fontSize: "16px" }}>
																		<p className="mb-5">Account Name:</p>
																		<strong style={{ color: "#0c3877" }}>
																			Father Saturnino Urios University
																		</strong>
																	</Col>
																	<Col>
																		<p className="mb-5">Account No:</p>
																		<strong style={{ color: "#0c3877" }}>
																			20014793495
																		</strong>
																	</Col>
																	<Col>
																		<p className="mb-5">Bank:</p>
																		<strong style={{ color: "#0c3877" }}>
																			EastWest Banking Corporation
																		</strong>{" "}
																		<p className="mt-0">
																			(P.Burgos St., Butuan City)
																		</p>
																	</Col>
																</Flex>
															</Col>

															<Col
																xs={24}
																sm={24}
																md={10}
																lg={10}
																xl={10}
																className="instruction-carousel"
															>
																<Carousel
																	ref={carouselRef}
																	arrows
																	infinite={false}
																	afterChange={afterChange}
																	prevArrow={<PrevArrow />}
																	nextArrow={<NextArrow />}
																>
																	{/* Step 1 */}
																	<div>
																		<h3 style={contentStyle}>
																			<img
																				src="/assets/images/transfer_to_bank_step_1.png"
																				alt="Open the GCash app and tap 'Transfer' on the dashboard"
																				style={{
																					maxWidth: "100%",
																					maxHeight: "100%",
																					objectFit: "cover",
																					justifyContent: "center",
																				}}
																			/>
																		</h3>
																	</div>
																	{/* Step 2 */}
																	<div>
																		<h3 style={contentStyle}>
																			<img
																				src="/assets/images/transfer_to_bank_step_2.png"
																				alt="Select a saved bank account, or choose one from the list of partner banks."
																				style={{
																					maxWidth: "100%",
																					maxHeight: "100%",
																					objectFit: "cover",
																					// justifyContent: "center",
																				}}
																			/>
																		</h3>
																	</div>
																	{/* Step 3 */}
																	<div>
																		<h3 style={contentStyle}>
																			<img
																				src="/assets/images/transfer_to_bank_step_3.png"
																				alt="Enter the required info and hit Send Money."
																				style={{
																					maxWidth: "100%",
																					maxHeight: "100%",
																					objectFit: "contain",
																					justifyContent: "center",
																				}}
																			/>
																		</h3>
																	</div>
																	{/* Step 4 */}
																	<div>
																		<h3 style={contentStyle}>
																			<img
																				src="/assets/images/transfer_to_bank_step_4.png"
																				alt="Review the details and hit Confirm."
																				style={{
																					maxWidth: "100%",
																					maxHeight: "100%",
																					objectFit: "contain",
																					justifyContent: "center",
																				}}
																			/>
																		</h3>
																	</div>
																	{/* Step 5 */}
																	<div>
																		<h3 style={contentStyle}>
																			<img
																				src="/assets/images/transfer_to_bank_step_5.png"
																				alt="View, download, or share your receipt. We will also send you a confirmation via email."
																				style={{
																					maxWidth: "100%",
																					maxHeight: "100%",
																					objectFit: "contain",
																					justifyContent: "center",
																				}}
																			/>
																		</h3>
																	</div>
																</Carousel>

																<Flex justify="center" className="mt-20">
																	<Col className="carousel-step">
																		{current + 1}
																	</Col>
																</Flex>
															</Col>
														</Flex>
													</Col>
												</>
											);
										}
									}}
								</Form.Item>
							</Flex>
						</Card>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Flex justify="space-between" className="mt-20">
							<Button
								variant="outlined"
								size="large"
								onClick={() => navigate(`/payment-qrscan/${params.qrcode}`)}
							>
								<FontAwesomeIcon icon={faArrowLeft} />
								&nbsp;Payment Method
							</Button>

							{/* <Tooltip
								title={
									!selectedPaymentMethod
										? "Please select method of payment first"
										: ""
								}
							>
								<Button
									type={selectedPaymentMethod ? "primary" : "default"}
									size="large"
									onClick={() =>
										navigate(
											`/payment-qrscan/online-confirmation/${params.qrcode}?${selectedPaymentMethod}`
										)
									}
									disabled={!selectedPaymentMethod}
								>
									Proceed
								</Button>
							</Tooltip> */}
						</Flex>
					</Col>
				</Col>
			</Row>
		</Form>
	);
}
