import { useEffect, useState } from "react";
import { Row, Button, Col, Table, notification, Popconfirm } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { POST } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearch,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../../providers/CustomTableFilter";
import notificationErrors from "../../../../providers/notificationErrors";
import ModalForm from "./ModalForm";

export default function PageExamCategory(props) {
	const {
		tableFilter,
		setTableFilter,
		selectedRowKeys,
		setSelectedRowKeys,
		dataExamCategory,
		refetchSourceExamCategory,
	} = props;

	const [toggleModalForm, setToggleModalForm] = useState({
		open: false,
		data: null,
	});

	useEffect(() => {
		if (dataExamCategory) {
			refetchSourceExamCategory();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const { mutate: mutateDeleteRefExamCategory } = POST(
		`api/multiple_archived_exam_category`,
		"exam_category_list"
	);

	const handleSelectedArchived = (isTrash) => {
		let data = {
			ids: selectedRowKeys,
		};
		mutateDeleteRefExamCategory(data, {
			onSuccess: (res) => {
				// console.log("res", res);
				if (res.success) {
					notification.success({
						message: "Exam Category",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Exam Category",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Button
					type="primary"
					className=" btn-main-primary btn-main-invert-outline b-r-none mr-10"
					icon={<FontAwesomeIcon icon={faPlus} />}
					onClick={() =>
						setToggleModalForm({
							open: true,
							data: null,
						})
					}
				>
					Add Exam Category
				</Button>

				<Button
					className={`btn-main-primary min-w-150 mr-10 ${
						tableFilter.isTrash === 0 ? "active" : "outlined"
					}`}
					onClick={() => {
						setTableFilter((ps) => ({
							...ps,
							isTrash: 0,
						}));
						setSelectedRowKeys([]);
					}}
				>
					Active
				</Button>

				<Button
					className={`btn-main-primary min-w-150 ${
						tableFilter.isTrash === 1 ? "active" : "outlined"
					}`}
					onClick={() => {
						setTableFilter((ps) => ({
							...ps,
							isTrash: 1,
						}));
						setSelectedRowKeys([]);
					}}
				>
					Archived
				</Button>

				{selectedRowKeys.length > 0 && (
					<Popconfirm
						title={
							<>
								Are you sure you want to
								<br />
								{tableFilter.isTrash === 1 ? "activate" : "archive"} the
								selected <br />
								{selectedRowKeys.length > 1
									? "exam categories"
									: "exam category"}
								?
							</>
						}
						okText="Yes"
						cancelText="No"
						onConfirm={() => {
							handleSelectedArchived(
								tableFilter.isTrash === 1 ? "Active" : "Archived"
							);
						}}
					>
						<Button
							className="btn-main-secondary ml-10"
							name="btn_active_archive"
						>
							{tableFilter.isTrash === 1 ? "ACTIVE" : "ARCHIVED"} SELECTED
						</Button>
					</Popconfirm>
				)}
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={12}>
				<Row gutter={[12, 12]}>
					<Col xs={24} sm={24} md={24}>
						<div className="tbl-top-filter">
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
							<TableGlobalSearch
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
					</Col>

					<Col xs={24} sm={24} md={24}>
						<Table
							className="ant-table-default ant-table-striped"
							dataSource={dataExamCategory && dataExamCategory.data.data}
							rowKey={(record) => record.id}
							pagination={false}
							bordered={false}
							scroll={{ x: "max-content" }}
							sticky={{
								offsetHeader: 0,
							}}
							rowSelection={{
								selectedRowKeys,
								onChange: (selectedRowKeys) => {
									setSelectedRowKeys(selectedRowKeys);
								},
							}}
						>
							<Table.Column
								title="Action"
								key="action"
								dataIndex="action"
								align="center"
								width={150}
								render={(text, record) => {
									return (
										<>
											<Button
												type="link"
												className="color-1"
												onClick={() =>
													setToggleModalForm({
														open: true,
														data: record,
													})
												}
											>
												<FontAwesomeIcon icon={faPencil} />
											</Button>
										</>
									);
								}}
							/>

							<Table.Column
								title="Exam Category"
								key="category"
								dataIndex="category"
								sorter={(a, b) => a.category.localeCompare(b.category)}
							/>

							<Table.Column
								title="Exam Fee"
								key="exam_fee"
								dataIndex="exam_fee"
								sorter={(a, b) => a.exam_fee - b.exam_fee}
							/>
						</Table>
					</Col>

					<Col xs={24} sm={24} md={24}>
						<div className="tbl-bottom-filter">
							<TableShowingEntriesV2 />
							<TablePagination
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
								setPaginationTotal={dataExamCategory?.data.total}
								showLessItems={true}
								showSizeChanger={false}
								tblIdWrapper="tbl_wrapper"
							/>
						</div>
					</Col>
				</Row>
			</Col>

			<ModalForm
				toggleModalForm={toggleModalForm}
				setToggleModalForm={setToggleModalForm}
			/>
		</Row>
	);
}
