import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-regular-svg-icons";

import { defaultProfile } from "../../../../providers/companyInfo";
import ModalUploadProfilePicture from "../components/ModalUploadProfilePicture";

export default function ApplicantFormPhotoInfo(props) {
	const {
		toggleModalUploadProfilePicture,
		setToggleModalUploadProfilePicture,
	} = props;

	return (
		<>
			<div className="profile-picture-wrapper">
				<img
					className="profile-picture"
					alt="Profile"
					src={
						toggleModalUploadProfilePicture.src
							? toggleModalUploadProfilePicture.src
							: defaultProfile
					}
				/>

				<Button
					type="link"
					icon={<FontAwesomeIcon icon={faCamera} />}
					className="btn-upload"
					onClick={() => {
						setToggleModalUploadProfilePicture((ps) => ({ ...ps, open: true }));
					}}
				/>
			</div>

			<ModalUploadProfilePicture
				toggleModalUploadProfilePicture={toggleModalUploadProfilePicture}
				setToggleModalUploadProfilePicture={setToggleModalUploadProfilePicture}
			/>
		</>
	);
}
