import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row, Form, Typography, Radio, Flex, Divider } from "antd";

import { capitalize } from "lodash";
import { GET } from "../../../../providers/useAxiosQuery";

import FloatSelect from "../../../../providers/FloatSelect";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import optionStudentStatus from "../../../../providers/optionStudentStatus";
import validateRules from "../../../../providers/validateRules";
import optionStrand from "../../../../providers/optionStrand";
import PreferredExamCalendar from "../components/PreferredExamCalendar";
import dayjs from "dayjs";

export default function ApplicantFormExamInfo(props) {
	const {
		form,
		handleStudentLevelChange,
		examSchedule,
		setExamSchedule,
	} = props;

	const location = useLocation();

	const [selectExamSchedule, setSelectExamSchedule] = useState();

	const [tableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		isTrash: 0,
	});

	const { data: dataSchoolLevel } = GET(`api/school_level`, "school_level");
	const { data: dataSchoolName } = GET(`api/school`, "school_list");

	const { data: dataExamScheduleSource } = GET(
		`api/exam_schedule?${new URLSearchParams(tableFilter)}`,
		"exam_schedule_list"
	);

	const { data: dataExamCategory } = GET(
		`api/exam_category`,
		"exam_category_list"
	);

	// console.log("dataExamCategory: ", dataExamCategory);

	const { data: dataCourse } = GET(`api/course`, "course_list");

	// Degree Programs Offered
	const renderCourseSelection = () => (
		<>
			<Col xs={24} sm={24} md={24} lg={24} xl={24} className="pl-10">
				<Typography.Title level={5} type="danger" className="mt-0">
					*For College Level Only
				</Typography.Title>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24} className="p-0 ml-10">
				<p className="mt-0 mb-0">
					<strong>Degree Programs Offered:</strong> Kindly select your top three
					(3) course choices.
				</p>
			</Col>

			{["first_course_id", "second_course_id", "third_course_id"].map(
				(course, index) => (
					<Col xs={24} sm={24} md={24} lg={24} xl={24} key={index}>
						<Form.Item name={course} rules={[validateRules.quillValidator]}>
							<FloatSelect
								label={`${capitalize(course.split("_")[0])} Course`}
								placeholder={`${capitalize(course.split("_")[0])} Course`}
								required={true}
								allowClear={true}
								options={[
									{ label: "Undecided", value: -1 },
									{ label: "NO CHOSEN COURSE", value: -2 },
									{ label: "Not in the list", value: -3 },
									...(dataCourse && dataCourse.data
										? dataCourse.data.map((item) => ({
												label: item.course_name,
												value: item.id,
										  }))
										: []),
								]}
							/>
						</Form.Item>
					</Col>
				)
			)}
		</>
	);

	useEffect(() => {
		const setExamDate = dataExamScheduleSource?.data?.data?.find(
			(item) => item.id === examSchedule
		);

		setSelectExamSchedule(setExamDate);
	}, [examSchedule, dataExamScheduleSource]);

	return (
		<>
			<Row gutter={[18, 0]}>
				<Col xs={24} sm={24} md={10} lg={24} xl={24}>
					<Flex>
						<Col xs={24} sm={24} md={10} lg={10} xl={10}>
							<p className="mt-0">
								<strong>NOTE</strong>
							</p>
							<ul>
								<li>
									Please select your preferred exam schedule. You can only
									select one date.
								</li>

								<li>
									Only dates with schedules and available slots are selectable.
								</li>
								<li>
									<span style={{ color: "grey" }}>
										<strong>Grey:</strong>{" "}
									</span>{" "}
									Indicates that the exam date is locked.
								</li>
								<li>
									<span style={{ color: "green" }}>
										<strong>Green:</strong>{" "}
									</span>{" "}
									Indicates there are available slots.
								</li>
								<li>
									<span style={{ color: "red" }}>
										<strong>Red:</strong>{" "}
									</span>{" "}
									Indicates no more available slots for that date.
								</li>
								<li>
									Click <strong>"+more"</strong> to view all schedules for a
									specific date.
								</li>
							</ul>
							<br />
						</Col>
					</Flex>

					<Divider />

					<Flex>
						{/* left column */}
						<Col xs={24} sm={24} md={10} lg={10} xl={10}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Typography.Title level={4} className="mt-0">
									Preferred Exam
								</Typography.Title>
							</Col>

							{/* Exam Schedule Calendar */}
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={24}
								xl={24}
								id="PreferredExamCalendar"
							>
								<Form.Item
									name="exam_schedule_id"
									rules={[validateRules.quillValidator]}
									initialValue={examSchedule}
								>
									<PreferredExamCalendar
										form={form}
										dataExamScheduleSource={dataExamScheduleSource}
										setExamSchedule={setExamSchedule}
									/>
								</Form.Item>
							</Col>
						</Col>

						{/* right column */}
						<Col xs={24} sm={24} md={14} lg={14} xl={14}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Typography.Title level={4} className="mt-0">
									Schedule Selected
								</Typography.Title>
							</Col>

							<Form.Item shouldUpdate noStyle>
								{() => {
									let exam_schedule = form.getFieldValue("exam_schedule_id");

									if (exam_schedule !== undefined || exam_schedule !== null) {
										return (
											<>
												<Col>
													{selectExamSchedule && (
														<Typography.Title level={5} className="mt-0">
															<span style={{ fontWeight: "normal" }}>
																Date:
															</span>{" "}
															<span
																className="pr-20"
																style={{ color: "#447ce2" }}
															>
																{dayjs(selectExamSchedule.exam_date).format(
																	"MMMM DD, YYYY"
																)}
															</span>
															<span style={{ fontWeight: "normal" }}>
																Time:
															</span>{" "}
															<span
																className="pr-20"
																style={{ color: "#447ce2" }}
															>
																{selectExamSchedule.time_in}{" "}
																{selectExamSchedule.time_in_meridiem} -{" "}
																{selectExamSchedule.time_out}{" "}
																{selectExamSchedule.time_out_meridiem}
															</span>
															<span style={{ fontWeight: "normal" }}>
																Available Slot/s:
															</span>{" "}
															<span
																className="pr-20"
																style={{ color: "#447ce2" }}
															>
																{selectExamSchedule.total_available_slot}
															</span>
														</Typography.Title>
													)}
												</Col>
											</>
										);
									} else {
										return null;
									}
								}}
							</Form.Item>

							<Divider />

							<Flex>
								{/* Exam Category */}
								<Col xs={24} sm={24} md={24} lg={12} xl={12}>
									<Form.Item
										name="exam_category_id"
										rules={[validateRules.required]}
									>
										<FloatSelect
											label="Exam Category"
											placeholder="Exam Category"
											required={true}
											options={
												dataExamCategory?.data?.map((item) => ({
													label: item.category,
													value: item.id,
												})) ?? []
											}
										/>
									</Form.Item>
								</Col>

								<Col xs={24} sm={24} md={24} lg={12} xl={12}>
									{/* Student Status */}
									<Form.Item shouldUpdate noStyle>
										{() => {
											let exam_category = form.getFieldValue(
												"exam_category_id"
											);

											if (exam_category === 1) {
												return (
													<>
														<Form.Item
															name="student_status"
															rules={[validateRules.quillValidator]}
														>
															<FloatSelect
																label="Student Status"
																placeholder="Student Status"
																required={true}
																options={[
																	{
																		label: "Pursuing a Second Degree",
																		value: "Pursuing a Second Degree",
																	},
																	...optionStudentStatus,
																]}
															/>
														</Form.Item>
													</>
												);
											} else if (exam_category !== 1) {
												return (
													<>
														<Form.Item
															name="student_status"
															rules={[validateRules.quillValidator]}
														>
															<FloatSelect
																label="Student Status"
																placeholder="Student Status"
																required={true}
																options={optionStudentStatus}
															/>
														</Form.Item>
													</>
												);
											} else {
												return null;
											}
										}}
									</Form.Item>
								</Col>
							</Flex>

							{/* Exam Fee */}
							<Form.Item shouldUpdate noStyle>
								{() => {
									let exam_category_id = form.getFieldValue("exam_category_id");

									if (exam_category_id) {
										// Find the corresponding exam fee
										let exam_fee =
											dataExamCategory &&
											dataExamCategory.data.find(
												(item) => item.id === exam_category_id
											)?.exam_fee;

										return (
											<Col
												xs={24}
												sm={24}
												md={24}
												lg={24}
												xl={24}
												className="p-0 ml-10"
											>
												<p className="mt-0 mb-20">
													<strong>
														Testing Fee:
														<span style={{ color: "#ff4d4f" }}>
															{" "}
															₱ {exam_fee}.00
														</span>
													</strong>
													<em>
														{" "}
														Please note that the entrance exam fee must be paid
														within three days prior to the exam date to secure
														your spot. Failure to pay the fee may result in the
														cancellation of your exam reservation.
													</em>
												</p>
											</Col>
										);
									}
								}}
							</Form.Item>

							{/* Current Academic Status */}
							<Form.Item shouldUpdate noStyle>
								{() => {
									let exam_category_id = form.getFieldValue("exam_category_id");
									let student_status = form.getFieldValue("student_status");

									if (
										exam_category_id === 1 &&
										student_status !== "Pursuing a Second Degree"
									) {
										return (
											<>
												<Col xs={24} sm={24} md={24} lg={24} xl={24}>
													<Typography.Title level={5} className="mt-0">
														Current Academic Status
													</Typography.Title>
												</Col>

												<Flex>
													{/* Student Level */}
													<Col xs={24} sm={24} md={24} lg={12} xl={12}>
														<Form.Item
															name="student_level_id"
															rules={[validateRules.quillValidator]}
														>
															<FloatSelect
																label="Student Level"
																placeholder="Student Level"
																required={true}
																options={
																	dataSchoolLevel && dataSchoolLevel.data
																		? dataSchoolLevel.data.map((item) => ({
																				label: item.school_level,
																				value: item.id,
																		  }))
																		: []
																}
																onChange={handleStudentLevelChange}
															/>
														</Form.Item>
													</Col>

													<Col xs={24} sm={24} md={24} lg={12} xl={12}>
														<Flex vertical>
															<Form.Item shouldUpdate noStyle>
																{() => {
																	let student_level_id = form.getFieldValue(
																		"student_level_id"
																	);

																	if (student_level_id === 5) {
																		return (
																			<>
																				{/* Currrent Course */}
																				<Form.Item
																					name="current_course_id"
																					rules={[validateRules.quillValidator]}
																				>
																					<FloatSelect
																						label="Current Course"
																						placeholder="Current Course"
																						required={true}
																						allowClear={true}
																						options={
																							dataCourse && dataCourse.data
																								? dataCourse.data.map(
																										(item) => ({
																											label: item.course_name,
																											value: item.id,
																										})
																								  )
																								: []
																						}
																					/>
																				</Form.Item>

																				{/* High School Strand */}
																				<Form.Item
																					name="student_strand"
																					rules={[validateRules.quillValidator]}
																				>
																					<FloatSelect
																						label="High School Strand"
																						placeholder="High School Strand"
																						required={true}
																						allowClear={true}
																						options={optionStrand}
																					/>
																				</Form.Item>
																			</>
																		);
																	} else if (student_level_id === 4) {
																		return (
																			<>
																				{/* Strand */}
																				<Form.Item
																					name="student_strand"
																					rules={[validateRules.quillValidator]}
																				>
																					<FloatSelect
																						label="Strand"
																						placeholder="Strand"
																						required={true}
																						allowClear={true}
																						options={optionStrand}
																					/>
																				</Form.Item>
																			</>
																		);
																	} else {
																		return null;
																	}
																}}
															</Form.Item>
														</Flex>
													</Col>
												</Flex>
											</>
										);
									} else {
										return null;
									}
								}}
							</Form.Item>

							{/* Degree Programs Offered */}
							<Form.Item shouldUpdate noStyle>
								{() => {
									let exam_category = form.getFieldValue("exam_category_id");
									let student_status = form.getFieldValue("student_status");

									if (exam_category === 1) {
										if (student_status !== "Pursuing a Second Degree") {
											return renderCourseSelection();
										} else {
											return null;
										}
									} else if (exam_category !== 1) {
										if (student_status !== "Pursuing a Second Degree") {
											return null;
										} else {
											return renderCourseSelection();
										}
									}
								}}
							</Form.Item>

							{/* Transferee */}
							{location.pathname.includes("add") ||
							location.pathname.includes("edit") ? (
								<Form.Item shouldUpdate noStyle>
									{() => {
										let student_status = form.getFieldValue("student_status");

										if (student_status === "Transferee") {
											return (
												<>
													<Col xs={24} sm={24} md={24} lg={24} xl={24}>
														<Typography.Title
															level={5}
															type="danger"
															className="mt-0"
														>
															*For Transferee Only
														</Typography.Title>
													</Col>

													<Col xs={24} sm={24} md={24} lg={24} xl={24}>
														<p className="mt-0">
															Please list all universities and/ or colleges you
															have attended and year attended. Failure to
															disclose previous attendance at another
															institution could result in academic dismissal.
														</p>
													</Col>

													<Flex>
														{/* School Attended */}
														<Col xs={24} sm={24} md={24} lg={12} xl={12}>
															<Form.Item
																name={["previous_school_name"]}
																rules={[validateRules.quillValidator]}
															>
																<FloatSelect
																	label="Name of School"
																	placeholder="Name of School"
																	allowClear
																	required={true}
																	options={
																		dataSchoolName && dataSchoolName.data
																			? dataSchoolName.data
																					.filter(
																						(item) => item.school_level_id === 5
																					)
																					.map((item) => ({
																						value: item.school_name,
																						label: item.school_name,
																					}))
																					.sort((a, b) =>
																						a.label.localeCompare(b.label)
																					)
																			: []
																	}
																/>
															</Form.Item>
														</Col>

														{/* Year Attended */}
														<Col>
															<Form.Item
																name={["previous_school_year"]}
																rules={[validateRules.quillValidator]}
															>
																<FloatDatePicker
																	label="Year Attended"
																	placeholder="Year Attended"
																	format="YYYY"
																	picker="year"
																	allowClear={true}
																	required={true}
																/>
															</Form.Item>
														</Col>
													</Flex>
												</>
											);
										} else {
											return null;
										}
									}}
								</Form.Item>
							) : null}
						</Col>
					</Flex>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Flex>
						{/* Applied to FSUU before */}
						<Col xs={24} sm={24} md={10} lg={10} xl={10}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<p className="mt-0">
									<Typography.Text type="danger">* </Typography.Text>Have you
									ever applied to FATHER SATURNINO URIOS UNIVERSITY before?
								</p>
							</Col>

							<Flex>
								<Col xs={24} sm={24} md={8} lg={8} xl={8}>
									<Form.Item
										name="applied_to_fsuu"
										rules={[validateRules.required]}
									>
										<Radio.Group>
											<Radio value="Yes">Yes</Radio>
											<Radio value="No">No</Radio>
										</Radio.Group>
									</Form.Item>
								</Col>

								<Col xs={24} sm={24} md={8} lg={8} xl={8}>
									<Form.Item shouldUpdate noStyle>
										{() => {
											let applied_to_fsuu = form.getFieldValue(
												"applied_to_fsuu"
											);

											if (applied_to_fsuu === "Yes") {
												return (
													<>
														<Form.Item
															name="year_applied"
															rules={[validateRules.quillValidator]}
														>
															<FloatDatePicker
																label="Year Applied"
																placeholder="Year Applied"
																format="YYYY"
																picker="year"
																allowClear={true}
															/>
														</Form.Item>
													</>
												);
											} else {
												return null;
											}
										}}
									</Form.Item>
								</Col>
							</Flex>

							<Form.Item shouldUpdate noStyle>
								{() => {
									let applied_to_fsuu = form.getFieldValue("applied_to_fsuu");

									if (applied_to_fsuu === "Yes") {
										return (
											<>
												<Col xs={24} sm={24} md={24} lg={24}>
													<p className="mt-0">Were you Accepted?</p>
												</Col>

												<Flex>
													<Col xs={24} sm={24} md={8} lg={8} xl={8}>
														<Form.Item name="accepted_to_fsuu">
															<Radio.Group>
																<Radio value="Yes">Yes</Radio>
																<Radio value="No">No</Radio>
															</Radio.Group>
														</Form.Item>
													</Col>

													<Form.Item shouldUpdate noStyle>
														{() => {
															let accepted_to_fsuu = form.getFieldValue(
																"accepted_to_fsuu"
															);

															if (accepted_to_fsuu === "Yes") {
																return (
																	<>
																		<Col xs={24} sm={24} md={8} lg={8} xl={8}>
																			<Form.Item
																				name="year_accepted"
																				rules={[validateRules.quillValidator]}
																			>
																				<FloatDatePicker
																					label="Year Accepted"
																					placeholder="Year Accepted"
																					format="YYYY"
																					picker="year"
																					allowClear={true}
																				/>
																			</Form.Item>
																		</Col>
																	</>
																);
															}
														}}
													</Form.Item>
												</Flex>

												<Col
													xs={24}
													sm={24}
													md={24}
													lg={24}
													xl={24}
													className="mt-0"
												>
													<p className="mt-0">Did you attend?</p>
												</Col>

												<Col xs={24} sm={24} md={24} lg={24} xl={24}>
													<Row gutter={[12, 0]}>
														<Col xs={24} sm={24} md={8} lg={8} xl={8}>
															<Form.Item
																name="attended_to_fsuu"
																className="mb-0"
															>
																<Radio.Group>
																	<Radio value="Yes">Yes</Radio>
																	<Radio value="No">No</Radio>
																</Radio.Group>
															</Form.Item>
														</Col>

														<Form.Item shouldUpdate noStyle>
															{() => {
																let attended_to_fsuu = form.getFieldValue(
																	"attended_to_fsuu"
																);

																if (attended_to_fsuu === "Yes") {
																	return (
																		<>
																			<Col xs={24} sm={24} md={8} lg={8} xl={8}>
																				<Form.Item
																					name="year_attended"
																					rules={[validateRules.quillValidator]}
																				>
																					<FloatDatePicker
																						label="Year Attended"
																						placeholder="Year Attended"
																						format="YYYY"
																						picker="year"
																						allowClear={true}
																					/>
																				</Form.Item>
																			</Col>
																		</>
																	);
																}
															}}
														</Form.Item>
													</Row>
												</Col>
											</>
										);
									} else {
										return null;
									}
								}}
							</Form.Item>
						</Col>

						{/* Scholarship */}
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Typography.Title level={5} className="mt-0">
									Scholarship
								</Typography.Title>
							</Col>
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={24}
								xl={24}
								className="p-0 ml-10"
							>
								<p>
									<Typography.Text type="danger">* </Typography.Text>Do you
									intent to apply for a scholarship?
								</p>
							</Col>

							<Col xs={24} sm={24} md={24} lg={24} xl={24} className="ml-10">
								<Form.Item
									name="apply_scholarship"
									className="mb-0"
									rules={[validateRules.required]}
								>
									<Radio.Group>
										<Radio value="Yes">Yes</Radio>
										<Radio value="No">No</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Col>
					</Flex>
				</Col>
			</Row>
		</>
	);
}
