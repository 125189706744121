import { useEffect, useState } from "react";
import {
	Row,
	Col,
	Table,
	Button,
	notification,
	Popconfirm,
	Tooltip,
	Tag,
	Flex,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";
import notificationErrors from "../../../../providers/notificationErrors";
import ModalFormValidation from "../../PageProfilingPortal/components/ModalFormValidation";
import ModalDataConsent from "./ModalDataConsent";
import dayjs from "dayjs";

export default function TableApplicant(props) {
	const { tableFilter, setTableFilter, selectExamSchedule } = props;

	const navigate = useNavigate();
	const location = useLocation();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [toggleModalFormValidation, setToggleModalFormValidation] = useState({
		open: false,
		data: null,
	});

	const [toggleModalDataConsent, setToggleModalDataConsent] = useState({
		open: false,
		data: null,
	});

	// console.log("selectedRowKeys: ", selectedRowKeys);

	const {
		data: dataStudentExam,
		refetch: refetchStudentExam,
	} = GET(
		`api/student_exams?${new URLSearchParams(tableFilter)}`,
		"student_exams_info",
		(res) => {}
	);

	const {
		mutate: mutateArchivedApplicant,
		isLoading: isLoadingArchivedApplicant,
	} = POST(`api/applicant_archived`, "student_exams_list");

	const handleSelectedArchived = (id) => {
		let data = {
			isTrash: tableFilter.isTrash,
			ids: selectedRowKeys.length > 0 ? selectedRowKeys : [id],
		};

		mutateArchivedApplicant(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Applicant",
						description: res.message,
					});
					refetchStudentExam();
				} else {
					notification.error({
						message: "Applicant",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		refetchStudentExam();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	useTableScrollOnTop("tblApplicant", location.pathname);

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper_applicant">
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-top-filter">
						<Flex gap={10}>
							{selectExamSchedule && selectExamSchedule.id && (
								<Button
									type="primary"
									icon={<FontAwesomeIcon icon={faPlus} />}
									onClick={() => {
										setToggleModalDataConsent({
											open: true,
											data: null,
										});
									}}
									name="btn_add"
								>
									Add Walk-In Applicant
								</Button>
							)}

							<Button
								type={tableFilter.isTrash === 0 ? "primary" : ""}
								className={`min-w-150 ${
									tableFilter.isTrash === 0 ? "active" : ""
								}`}
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										isTrash: 0,
									}));
									setSelectedRowKeys([]);
								}}
							>
								Active
							</Button>

							<Button
								type={tableFilter.isTrash === 1 ? "primary" : ""}
								className={`min-w-150 ${
									tableFilter.isTrash === 1 ? "active" : ""
								}`}
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										isTrash: 1,
									}));
								}}
							>
								Archived
							</Button>

							<TableGlobalSearchAnimated
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>

							{selectedRowKeys.length > 0 && (
								<Popconfirm
									title={
										<>
											Are you sure you want to
											<br />
											{tableFilter.isTrash === 0 ? "archive" : "restore"} the
											selected{" "}
											{selectedRowKeys.length > 1 ? "applicants" : "applicant"}?
										</>
									}
									okText="Yes"
									cancelText="No"
									onConfirm={() => {
										handleSelectedArchived();
									}}
									disabled={isLoadingArchivedApplicant}
								>
									<Button
										type="primary"
										name="btn_active_archive"
										loading={isLoadingArchivedApplicant}
										danger={tableFilter.isTrash === 0}
										className={tableFilter.isTrash === 1 ? "btn-success" : ""}
									>
										RESTORE SELECTED
									</Button>
								</Popconfirm>
							)}
						</Flex>

						<Flex gap={10}>
							<TableShowingEntriesV2 />
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</Flex>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<Table
						id="tblApplicant"
						className="ant-table-default ant-table-striped"
						dataSource={dataStudentExam && dataStudentExam.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						scroll={{ x: "max-content" }}
						sticky={{
							offsetHeader: 0,
						}}
						rowSelection={
							tableFilter.isTrash === 1
								? {
										selectedRowKeys,
										onChange: (selectedRowKeys) => {
											setSelectedRowKeys(selectedRowKeys);
										},
								  }
								: null
						}
					>
						{tableFilter.isTrash === 0 && (
							<Table.Column
								title="Action"
								key="action"
								dataIndex="action"
								align="center"
								width={50}
								render={(text, record) => {
									return (
										<Flex justify="center">
											<Tooltip title={"Edit Information"}>
												<Button
													type="link"
													className="primary-color"
													onClick={() => {
														navigate(
															`/applicants/walk-in/edit/${record.profile_id}`
														);
													}}
													name="btn_edit"
												>
													<FontAwesomeIcon icon={faPencil} />
												</Button>
											</Tooltip>

											<Tooltip
												title={
													tableFilter.isTrash === 0 ? "Archive" : "Restore"
												}
											>
												<Popconfirm
													title={
														<>
															Are you sure you want to
															<br />
															archive the applicant?
														</>
													}
													okText="Yes"
													cancelText="No"
													onConfirm={() => {
														handleSelectedArchived(record.id);
													}}
													disabled={isLoadingArchivedApplicant}
												>
													<Button
														type="link"
														className="primary-color text-danger"
														name="btn_edit"
													>
														<FontAwesomeIcon icon={faTrash} />
													</Button>
												</Popconfirm>
											</Tooltip>
										</Flex>
									);
								}}
							/>
						)}

						<Table.Column
							title="Student Name"
							key="fullname"
							dataIndex="fullname"
							sorter={(a, b) => a.fullname.localeCompare(b.fullname)}
							sortDirections={["descend", "ascend"]}
							width={250}
						/>

						<Table.Column
							title="Profile Status"
							key="verify_status"
							dataIndex="verify_status"
							align="center"
							sorter={(a, b) => a.verify_status - b.verify_status}
							width={200}
							render={(text, record) => {
								return (
									<Button
										style={{
											background:
												record.verify_status === "Validated"
													? "#4EB459"
													: "#114eaf",
											color: "#ffff",
										}}
										onClick={() =>
											setToggleModalFormValidation({
												open: true,
												data: record,
											})
										}
									>
										{record.verify_status}
									</Button>
								);
							}}
						/>

						<Table.Column
							title="Notes"
							key="verify_notes"
							dataIndex="verify_notes"
							width={250}
							// render={(text, record) => console.log("record: ", record)}
						/>

						<Table.Column
							title="Exam Schedule"
							key="exam_category"
							dataIndex="exam_category"
							sorter={(a, b) => a.exam_category.localeCompare(b.exam_category)}
							defaultSortOrder="ascend"
							render={(text, record) => (
								<>
									{dayjs(record.exam_date).format("MMMM DD, YYYY")} ({" "}
									{record.exam_time} )
								</>
							)}
						/>

						<Table.Column
							title="Exam Category"
							key="exam_category"
							dataIndex="exam_category"
							sorter={(a, b) => a.exam_category.localeCompare(b.exam_category)}
							defaultSortOrder="ascend"
							render={(text, record) => (
								<>
									{record.exam_category}{" "}
									<Tag color="blue" style={{ fontSize: "12px" }}>
										₱ {record.exam_fee}.00
									</Tag>
								</>
							)}
						/>

						<Table.Column
							title="Payment Status"
							key="payment_status"
							dataIndex="payment_status"
							sorter={(a, b) => a.payment_status - b.payment_status}
							width={200}
							render={(text, record) => {
								return record.payment_status === "Not Paid" ? (
									<Tag color="#f50" style={{ fontSize: "12px" }}>
										Not Paid
									</Tag>
								) : (
									<Tag color="#87d068" style={{ fontSize: "12px" }}>
										Paid
									</Tag>
								);
							}}
						/>
					</Table>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-bottom-filter">
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							setPaginationTotal={dataStudentExam?.data?.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper_applicant"
						/>
					</div>
				</Col>

				<ModalFormValidation
					toggleModalFormValidation={toggleModalFormValidation}
					setToggleModalFormValidation={setToggleModalFormValidation}
					refetchStudentExam={refetchStudentExam}
				/>

				<ModalDataConsent
					toggleModalDataConsent={toggleModalDataConsent}
					setToggleModalDataConsent={setToggleModalDataConsent}
					selectExamSchedule={selectExamSchedule}
				/>
			</Row>
		</>
	);
}
