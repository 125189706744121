import { useEffect } from "react";
import { Modal, Button, Form, notification, Flex } from "antd";
import { useNavigate } from "react-router-dom";

import { POST } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";
import FloatInput from "../../../../providers/FloatInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";

export default function ModalFormReturningApplicant(props) {
	const {
		toggleModalFormReturningApplicant,
		setToggleModalFormReturningApplicant,
	} = props;

	const [form] = Form.useForm();
	const navigate = useNavigate();

	const {
		mutate: mutateExistingUsername,
		isLoading: isLoadingExamCategory,
	} = POST(`api/existing_username`, "existing_username_list");

	const onFinish = (values) => {
		let data = {
			...values,
		};

		mutateExistingUsername(data, {
			onSuccess: (res) => {
				console.log("res", res);
				if (res.success) {
					navigate(`/profiling-portal/edit/${res.user_id}`);
					form.resetFields();
					notification.success({
						message: "Username",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Username",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notification.error({
					message: "Username",
					description:
						"The username does not exist. Please ensure your username is correct, or proceed with a New application",
				});
			},
		});
	};

	useEffect(() => {
		if (toggleModalFormReturningApplicant.open) {
			form.setFieldsValue({
				...toggleModalFormReturningApplicant.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormReturningApplicant]);

	return (
		<Modal
			title={
				<Flex align="center" gap={10}>
					<FontAwesomeIcon icon={faUser} /> FORM RETURNING APPLICANT
				</Flex>
			}
			className="modal-returning-applicant"
			open={toggleModalFormReturningApplicant.open}
			onCancel={() => {
				setToggleModalFormReturningApplicant({
					open: false,
					data: null,
				});
				form.resetFields();
			}}
			forceRender
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalFormReturningApplicant({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					key={2}
					onClick={() => form.submit()}
					loading={isLoadingExamCategory}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item
					name="username"
					rules={[validateRules.required]}
					className="mb-5"
				>
					<FloatInput label="Username" placeholder="Username" required={true} />
				</Form.Item>
			</Form>

			<em className="font-color-grey">
				If you have previously applied for an exam schedule, use the same
				username provided at that time.
			</em>
		</Modal>
	);
}
