import { Button, Col, Flex, Layout, Row } from "antd";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import ModalFormReturningApplicant from "./components/ModalFormReturningApplicant";
import ModalDataConsent from "../PageApplicants/components/ModalDataConsent";

let isLoggedIn = localStorage.getItem("token");

export default function PageProfilingPortal() {
	const [toggleModalDataConsent, setToggleModalDataConsent] = useState({
		open: false,
		data: null,
	});

	const [
		toggleModalFormReturningApplicant,
		setToggleModalFormReturningApplicant,
	] = useState({
		open: false,
		data: null,
	});

	if (isLoggedIn) {
		return (
			<>
				<Layout
					id="PageProfilingPortal"
					style={{
						minHeight: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Flex
						justify="flex-end"
						align="flex-end"
						style={{ position: "absolute", top: 0, right: 0 }}
					>
						<img
							width="400px"
							src="./assets/images/fsuu_logo_v2.png"
							alt="fsuu-logo"
						/>
					</Flex>

					<Row gutter={[0, 12]} style={{ width: "100%" }}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Flex justify="center">
								<Flex vertical gap={50}>
									<Flex vertical>
										<Button
											className="btn draw-border br-0 glass"
											// onClick={() => navigate("/profiling-portal/edit")}
											onClick={() =>
												setToggleModalFormReturningApplicant({
													open: true,
													// data: record,
												})
											}
										>
											<Flex justify="center" vertical gap={10}>
												<FontAwesomeIcon
													icon={faUser}
													style={{ fontSize: "50px" }}
												/>
												<span>Returning Applicant</span>
											</Flex>
										</Button>
									</Flex>

									<Flex vertical>
										<Button
											className="btn draw-border br-0"
											// onClick={() => navigate("/profiling-portal/add")}
											onClick={() =>
												setToggleModalDataConsent({
													open: true,
												})
											}
										>
											<Flex justify="center" vertical gap={10}>
												<FontAwesomeIcon
													icon={faUserPlus}
													style={{ fontSize: "50px" }}
												/>
												<span>New Applicant</span>
											</Flex>
										</Button>
									</Flex>
								</Flex>
							</Flex>
						</Col>
					</Row>
				</Layout>

				<ModalFormReturningApplicant
					toggleModalFormReturningApplicant={toggleModalFormReturningApplicant}
					setToggleModalFormReturningApplicant={
						setToggleModalFormReturningApplicant
					}
				/>

				<ModalDataConsent
					toggleModalDataConsent={toggleModalDataConsent}
					setToggleModalDataConsent={setToggleModalDataConsent}
				/>
			</>
		);
	} else {
		return <Navigate to="/" />;
	}
}
