const convertQrCodeToImage = (qrCodeId, codeName, fsuuLogo) => {
	const svg = document.getElementById(qrCodeId)?.querySelector("svg");
	if (!svg) {
		console.error(`SVG element with id ${qrCodeId} not found`);
		return Promise.reject(new Error("SVG element not found"));
	}

	return new Promise((resolve, reject) => {
		// logo
		const fsuuLogoImage = document.getElementById(fsuuLogo);

		let canvas = document.createElement("canvas");
		canvas.width = fsuuLogoImage.naturalWidth;
		canvas.height = fsuuLogoImage.naturalHeight;

		canvas.getContext("2d").drawImage(fsuuLogoImage, 0, 0);

		let fsuuLogoImageUrl = canvas
			.toDataURL("image/png")
			.replace(/^data:image\/(png|jpg);base64,/, "");

		// end logo

		// svg to image
		const svgData = new XMLSerializer().serializeToString(svg);
		const svgDataBase64 = btoa(unescape(encodeURIComponent(svgData)));
		const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`;

		const image = new Image();
		image.src = svgDataUrl;

		image.onload = () => {
			window.devicePixelRatio = 2;

			const width = 300;
			const height = 300;

			const canvas2 = document.createElement("canvas");
			canvas2.width = width;
			canvas2.height = height;
			const context2 = canvas2.getContext("2d");
			context2.fillStyle = "#ffffff";
			context2.fillRect(0, 0, width, height);
			context2.fillStyle = "#ffffff";
			context2.fillRect(5, 5, width - 10, height - 10);
			context2.drawImage(image, 10, 10, width - 20, height - 20);

			const imgLogoImage = new Image();
			imgLogoImage.src = `data:image/png;base64,${fsuuLogoImageUrl}`;

			imgLogoImage.onload = () => {
				context2.drawImage(imgLogoImage, 120, 120, width - 240, height - 240);

				canvas2.toBlob((blob) => {
					if (blob) {
						const file = new File([blob], codeName, {
							type: "image/png",
						});
						resolve(file);
					} else {
						reject(new Error("Canvas toBlob failed"));
					}
				}, "image/png");
			};

			imgLogoImage.onerror = () => {
				reject(new Error("Logo image loading failed"));
			};
		};

		image.onerror = () => {
			reject(new Error("SVG image loading failed"));
		};

		// end svg to image
	});
};

export default convertQrCodeToImage;
