import { Modal, Button, Form, Divider, Flex, Empty } from "antd";

import validateRules from "../../../../providers/validateRules";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatTextArea from "../../../../providers/FloatTextArea";
import { useEffect, useState } from "react";
import ModalAuthPassword from "./ModalAuthPassword";

export default function ModalFormORValidation(props) {
	const {
		toggleModalFormORValidation,
		setToggleModalFormORValidation,
		refetchStudentExam,
		tableFilter,
	} = props;

	// console.log(
	// 	"second toggle ModalFormORValidation: ",
	// 	toggleModalFormORValidation
	// );

	// console.log("refetchStudentExam:", refetchStudentExam);

	const [form] = Form.useForm();
	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const [fileImage, setFileImage] = useState({
		is_camera: false,
		status: null,
		isCapture: false,
		file: null,
		src: null,
		fileName: null,
	});

	useEffect(() => {
		if (toggleModalFormORValidation.open) {
			let data = { ...toggleModalFormORValidation.data };

			if (data.file_path) {
				const fileUrl = `${process.env.REACT_APP_API_URL}/${data.file_path}`;
				setFileImage((ps) => ({
					...ps,
					src: fileUrl,
					file: data.file_path,
					fileName: data.attachment_name || "Uploaded File",
				}));
			}

			form.setFieldsValue(data);
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormORValidation]);

	return (
		<>
			<Modal
				title="VALIDATE OFFICIAL RECEIPT "
				open={toggleModalFormORValidation.open}
				onCancel={() => {
					setToggleModalFormORValidation({
						open: false,
						data: null,
					});
				}}
				footer={[
					<Button
						className="btn-main-primary outlined"
						key={1}
						onClick={() => {
							setToggleModalFormORValidation({
								open: false,
								data: null,
							});
							form.resetFields();
						}}
					>
						CANCEL
					</Button>,
					<Button
						className="btn-main-primary"
						type="primary"
						key={2}
						onClick={() => {
							setToggleModalFormORValidation({ open: false });

							form.validateFields().then((formValues) => {
								let values = {
									...formValues,
									id: toggleModalFormORValidation.data.id,
									or_number: toggleModalFormORValidation.data.or_number,
								};

								setToggleModalAuthPassword({
									open: true,
									data: values,
									tableFilter: tableFilter,
									// from: "Payment Approval List View",
								});
							});
						}}
					>
						SUBMIT
					</Button>,
				]}
			>
				<Form form={form}>
					<Flex gap={20}>
						<div style={{ fontSize: "16px", width: "150px" }}>
							Full Name
							<br />
							OR Number
						</div>
						<div
							style={{
								fontSize: "16px",
								// textDecoration: "underline",
								marginTop: "0px",
								fontWeight: "600",
								width: "100%",
							}}
						>
							{toggleModalFormORValidation.data?.fullname}
							<br />
							{toggleModalFormORValidation.data?.or_number}
							<br />
						</div>
					</Flex>

					{fileImage.src ? (
						<img
							alt={fileImage.fileName || "Validation Document"}
							src={fileImage.src}
							className="w-100"
						/>
					) : (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description="No File Attached"
						/>
					)}

					<Divider />

					<Form.Item name="payment_status" rules={[validateRules.required]}>
						<FloatSelect
							placeholder="Payment Status"
							label="Payment Status"
							required={true}
							value={
								toggleModalFormORValidation.data &&
								toggleModalFormORValidation.data.payment_status
							}
							options={[
								{ value: "Not Paid", label: "For Validation" },
								{ value: "Paid", label: "Validated" },
							]}
						/>
					</Form.Item>

					<Form.Item name="payment_notes">
						<FloatTextArea placeholder="Notes" label="Notes" />
					</Form.Item>
				</Form>
			</Modal>

			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
				refetchStudentExam={refetchStudentExam}
			/>
		</>
	);
}
