import { useState } from "react";
import { Row, Col, Table, Button, Tag, Tooltip } from "antd";

import { GET } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";
import ModalUploadOR from "./ModalUploadOR";
import ModalFormORValidation from "./ModalFormORValidation";

export default function TablePayment(props) {
	const { tableFilter, setTableFilter, location } = props;

	const [toggleModalUploadOR, setToggleModalUploadOR] = useState({
		open: false,
		data: null,
	});

	const [
		toggleModalFormORValidation,
		setToggleModalFormORValidation,
	] = useState({
		open: false,
		data: null,
	});

	const {
		data: dataStudentExam,
		refetch: refetchStudentExam,
	} = GET(
		`api/student_exams?${new URLSearchParams(tableFilter)}`,
		"student_exams_info",
		(res) => {}
	);

	// useEffect(() => {
	// 	refetchStudentExam();

	// 	return () => {};
	// }, [tableFilter]);

	useTableScrollOnTop("tblPayment", location.pathname);

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper_approval">
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-top-filter">
						<div style={{ display: "flex", gap: 8 }}>
							<Button
								type={
									tableFilter.payment_status === "Not Paid"
										? "primary"
										: "default"
								}
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										payment_status: "Not Paid",
									}));
								}}
								className={
									tableFilter.payment_status === "Not Paid" &&
									"btn-main-primary"
								}
							>
								Not Paid
							</Button>

							<Button
								type={
									tableFilter.payment_status === "Paid" ? "primary" : "default"
								}
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										payment_status: "Paid",
									}));
								}}
								className={
									tableFilter.payment_status === "Paid" && "btn-main-primary"
								}
							>
								Paid
							</Button>

							<TableGlobalSearchAnimated
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
						<div style={{ display: "flex", gap: 12 }}>
							<TableShowingEntriesV2 />
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<Table
						id="tblPayment"
						className="ant-table-default ant-table-striped"
						dataSource={dataStudentExam && dataStudentExam.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						scroll={{ x: "max-content" }}
						sticky={{
							offsetHeader: 0,
						}}
					>
						<Table.Column
							title="Action"
							key="action"
							dataIndex="action"
							align="center"
							width={50}
							render={(text, record) => {
								let isPaid = record.payment_status;

								return (
									<Tooltip title={isPaid === "Paid" ? "View OR" : "Upload OR"}>
										<Button
											type="primary"
											className="primary-color"
											name="btn_edit"
											onClick={() => {
												setToggleModalUploadOR({
													open: true,
													data: record,
												});
											}}
										>
											{isPaid === "Paid" ? "View OR" : "Upload OR"}
										</Button>
									</Tooltip>
								);
							}}
						/>

						{tableFilter.payment_status === "Not Paid" && (
							<>
								<Table.Column
									title="Payment Status"
									key="payment_status"
									dataIndex="payment_status"
									width={100}
									render={(text, record) => {
										return (
											<Tooltip
												title={
													record.or_number === null
														? "No OR Attached"
														: "For Validation"
												}
											>
												<Button
													type={
														record.or_number === null ? "dashed" : "primary"
													}
													className="primary-color"
													name="btn_edit"
													onClick={() => {
														setToggleModalFormORValidation({
															open: true,
															data: record,
														});
													}}
													disabled={record.or_number === null}
												>
													{record.or_number === null
														? "No OR Attached"
														: "For Validation"}
												</Button>
											</Tooltip>
										);
									}}
								/>

								<Table.Column
									title="Notes"
									key="payment_notes"
									dataIndex="payment_notes"
									width={100}
								/>
							</>
						)}

						<Table.Column
							title="Official Receipt"
							key="or_number"
							dataIndex="or_number"
							sorter={(a, b) => a.or_number - b.or_number}
							width={150}
						/>

						<Table.Column
							title="Student Name"
							key="fullname"
							dataIndex="fullname"
							sorter={(a, b) => a.fullname.localeCompare(b.fullname)}
							width={200}
						/>

						<Table.Column
							title="Exam Category"
							key="exam_category_id"
							dataIndex="exam_category_id"
							width={150}
							sorter={(a, b) => a.exam_category - b.exam_category}
							render={(text, record) => (
								<>
									{record.exam_category}{" "}
									<Tag color="blue" style={{ fontSize: "12px" }}>
										₱ {record.exam_fee}.00
									</Tag>
								</>
							)}
						/>

						<Table.Column
							title="Payment Status"
							key="or_number"
							dataIndex="or_number"
							sorter={(a, b) => a.payment_status - b.payment_status}
							width={150}
							render={(text, record) => {
								return (
									<Tag
										color={
											record.payment_status === "Not Paid" ? "#f50" : "#87d068"
										}
										style={{ fontSize: "12px" }}
									>
										{record.payment_status}
									</Tag>
								);
							}}
						/>
					</Table>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-bottom-filter">
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							setPaginationTotal={dataStudentExam?.data?.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper_approval"
						/>
					</div>
				</Col>
			</Row>

			<ModalUploadOR
				toggleModalUploadOR={toggleModalUploadOR}
				setToggleModalUploadOR={setToggleModalUploadOR}
				setToggleModalFormORValidation={setToggleModalFormORValidation}
				tableFilter={tableFilter}
				refetchStudentExam={refetchStudentExam}
			/>

			<ModalFormORValidation
				toggleModalFormORValidation={toggleModalFormORValidation}
				setToggleModalFormORValidation={setToggleModalFormORValidation}
				refetchStudentExam={refetchStudentExam}
				tableFilter={tableFilter}
			/>
		</>
	);
}
