import {
	Modal,
	Button,
	Typography,
	Col,
	Checkbox,
	Divider,
	Form,
	Flex,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export default function ModalDataConsent(props) {
	const {
		toggleModalDataConsent,
		setToggleModalDataConsent,
		selectExamSchedule,
	} = props;

	console.log("selectExamSchedule: ", selectExamSchedule);

	const [form] = Form.useForm();
	const location = useLocation();
	const navigate = useNavigate();

	const scrollToSection = (id) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<Modal
			width={800}
			title="DATA PRIVACY CONSENT"
			open={toggleModalDataConsent.open}
			onCancel={() => {
				setToggleModalDataConsent({
					open: false,
					data: null,
				});
			}}
			forceRender
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalDataConsent({
							open: false,
							data: null,
						});
					}}
				>
					CLOSE
				</Button>,
				<Button
					type="primary"
					htmlType="submit"
					onClick={() => {
						form
							.validateFields()
							.then((values) => {
								console.log("Form values:", values);
								setToggleModalDataConsent({
									open: false,
								});
								{
									location.pathname === "/applicants/walk-in"
										? navigate("/applicants/walk-in/add", {
												state: {
													stateDataConsent: values.data_consent,
													stateExamSchedule: selectExamSchedule
														? selectExamSchedule.id
														: "",
												},
										  })
										: navigate("/profiling-portal/add", {
												state: {
													stateDataConsent: values.data_consent,
													stateExamSchedule: selectExamSchedule
														? selectExamSchedule.id
														: "",
												},
										  });
								}
							})
							.catch((errorInfo) => {
								console.log("Data Consent Failed");
							});
					}}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form}>
				<section id="section-data-privacy-consent">
					<Typography.Title
						level={5}
						style={{
							textAlign: "center",
							marginTop: "10px",
							justifyContent: "justify",
						}}
					>
						Terms and Conditions
					</Typography.Title>

					<Typography.Title
						level={5}
						style={{
							textAlign: "center",
							marginTop: "10px",
							justifyContent: "justify",
						}}
					>
						Privacy Policy
					</Typography.Title>

					<p
						style={{
							fontSize: "14px",
							marginBottom: "0px",
							textAlign: "justify",
						}}
					>
						By accepting this Data Privacy Statement, I grant my free,
						voluntary, and unconditional consent to the collection and
						processing of all Personal data to the information system of Father
						Saturnino Urios University Guidance Center by whatever means in
						accordance with the Republic Act 10173, otherwise known as the “Data
						Privacy Act of 2012” of the Republic of the Philippines, including
						its Implementing Rules and Regulations (IRR) as well as other
						guidelines and issuances by the National Privacy Commission (NPC). I
						also consent to the following:
					</p>
					<br />
					<ol>
						<li>
							<p>
								The FSUU Guidance Center may collect personal data during my
								application for admission purposes.
							</p>
						</li>
						<li>
							<p>
								My personal information can be accessed and used only by
								authorized personnel and officials connected to the University
								for legitimate purposes only.
							</p>
						</li>
						<li>
							<p>
								The FSUU Guidance Center may share or disclose some of my
								personal information to others in its process to deliver
								necessary services for the stakeholders and the institution,
								including but not limited to:
							</p>

							<ol style={{ listStyleType: "lower-alpha" }}>
								<li>
									Sharing information for accreditation purposes (PAASCU, CHED)
								</li>
								<li>
									Conducting research or surveys for purposes of institutional
									development
								</li>
								<li>
									All guidance and counseling-related functions and services
								</li>
								<li>Other legitimate processes of the University</li>
							</ol>
						</li>
					</ol>
				</section>

				<Divider />

				{/* Data Consent */}
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Typography.Title level={5} className="mt-0">
						Data Consent
					</Typography.Title>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.Item
						name="data_consent"
						valuePropName="checked"
						className="mb-0"
						rules={[
							{
								validator: (_, value) =>
									value && value.includes("Agree to Data Privacy Consent")
										? Promise.resolve()
										: Promise.reject(new Error("This field is required.")),
							},
						]}
					>
						<Checkbox.Group>
							<Flex vertical>
								<Flex>
									<Checkbox value="Agree to Data Privacy Consent">
										By clicking this, I confirm I have read and agree to the
									</Checkbox>
									<Typography.Link
										onClick={() =>
											scrollToSection("section-data-privacy-consent")
										}
									>
										Terms and Conditions & Privacy Policy
										<Typography.Text type="danger">*</Typography.Text>
									</Typography.Link>
								</Flex>
							</Flex>
						</Checkbox.Group>
					</Form.Item>
				</Col>
			</Form>
		</Modal>
	);
}
