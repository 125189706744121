import { useEffect } from "react";
import { Modal, Button, Form, notification } from "antd";

import { POST } from "../../../../providers/useAxiosQuery";
import FloatInputPassword from "../../../../providers/FloatInputPassword";
import validateRules from "../../../../providers/validateRules";
import notificationErrors from "../../../../providers/notificationErrors";
import { useLocation } from "react-router-dom";

export default function ModalAuthPassword(props) {
	const {
		toggleModalAuthPassword,
		setToggleModalAuthPassword,
		refetchStudentExam,
		setToggleModalFormProfile,
		onFinish2,
	} = props;

	// console.log("toggleModalAuthPassword: ", toggleModalAuthPassword);

	const [form] = Form.useForm();
	const location = useLocation();

	const {
		mutate: mutateMultipleApplicantAuth,
		isLoading: isLoadingMultipleApplicantAuth,
	} = POST(`api/applicant_auth`, "student_exams_list");

	const onFinish = (values) => {
		const { tableFilter, data, from } = toggleModalAuthPassword;

		const tabActive = tableFilter?.tabActive;
		const commonPayload = {
			...values,
			from: tabActive || from,
			link_origin: window.location.origin,
		};

		let payload;

		if (tabActive === "Payment Approval List View") {
			payload = { ...commonPayload, newValues: data };
		} else if (tabActive === "Exam Status List View") {
			payload = {
				...commonPayload,
				newValues: data?.map(
					({ id, fullname, exam_sheet_number, exam_status }) => ({
						id,
						fullname: fullname ?? null,
						exam_sheet_number: exam_sheet_number ?? null,
						exam_status: exam_status ?? null,
					})
				),
			};
		} else if (tabActive === "Exam Result List View") {
			const formData = new FormData();
			formData.append("exam_category_id", parseInt(data?.exam_category_id, 10));
			if (data?.file_excel?.[0]?.originFileObj) {
				formData.append("file_excel", data.file_excel[0].originFileObj);
			}
			formData.append("from", "Exam Result List View");
			formData.append("link_origin", window.location.origin);
			formData.append("auth_password", values.auth_password);
			payload = formData;
		} else if (from === "Profile Validation List View") {
			payload = {
				...commonPayload,
				newValues: {
					...data,
					exam_schedule_id: toggleModalAuthPassword.exam_schedule_id,
				},
			};
		} else {
			onFinish2(values.auth_password);
			return; // Exit the function early to avoid calling mutateMultipleApplicantAuth
		}

		console.log("payload: ", payload);

		mutateMultipleApplicantAuth(payload, {
			onSuccess: (res) => {
				notification.success({
					message: "Success",
					description: res.message,
				});
				setToggleModalAuthPassword({ open: false, data: null });
				form.resetFields();
				refetchStudentExam();
				if (
					setToggleModalFormProfile &&
					!location.pathname.includes("applicants")
				) {
					setToggleModalFormProfile({ open: false, data: null });
				}
			},
			onError: notificationErrors,
		});
	};

	useEffect(() => {
		if (toggleModalAuthPassword.open) {
			form.resetFields();
		}
	}, [toggleModalAuthPassword]);

	return (
		<Modal
			title="Authentication Required"
			open={toggleModalAuthPassword.open}
			onCancel={() => {
				setToggleModalAuthPassword({
					open: false,
					data: null,
				});
				form.resetFields();
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalAuthPassword({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					htmlType="submit"
					type="primary"
					key={2}
					loading={isLoadingMultipleApplicantAuth}
					onClick={() => form.submit()}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="auth_password" rules={[validateRules.quillValidator]}>
					<FloatInputPassword
						label="Password"
						placeholder="Password"
						required
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}
