import { Button } from "antd";
import { useLocation } from "react-router-dom";
import React, { useRef } from "react";

import dayjs from "dayjs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import moment from "moment-timezone";

export default function PreferredExamCalendar(props) {
	const {
		form,
		dataExamScheduleSource,
		setExamSchedule,
		selectExamSchedule,
	} = props;
	const location = useLocation();

	console.log("selectExamSchedule: ", selectExamSchedule);

	moment.tz.setDefault("Asia/Manila");
	const calendarRef = useRef(null);

	let eventCount = 0;

	const currentDate = dayjs().startOf("day");

	const eventData = dataExamScheduleSource?.data?.data?.map((item) => {
		const {
			exam_date,
			available_slots,
			slots,
			time_in,
			time_in_meridiem,
			time_out,
			time_out_meridiem,
		} = item;

		const isBeforeCurrentDate = dayjs(exam_date).isBefore(currentDate);

		return {
			title: `Exam Schedule  ${available_slots} / ${slots}`,
			time: `( ${time_in} ${time_in_meridiem} - ${time_out} ${time_out_meridiem} )`,
			start: dayjs(
				`${exam_date} ${time_in} ${time_in_meridiem}`,
				"YYYY-MM-DD hh:mm A"
			).format("YYYY-MM-DD HH:mm:ss"),
			end: dayjs(
				`${exam_date} ${time_out} ${time_out_meridiem}`,
				"YYYY-MM-DD HH:mm:ss"
			).format("YYYY-MM-DD HH:mm:ss"),
			color: isBeforeCurrentDate
				? "grey"
				: available_slots === 0
				? "#FE0000"
				: "green",
			classNames: isBeforeCurrentDate
				? "disable-exam-date"
				: available_slots === 0
				? "no-available"
				: "available",
			item: item,
		};
	});

	const currentDay = new Date().toLocaleString("en-us", {
		weekday: "short",
	});

	// Set default time 08:00 AM - 12:00 PM
	const onSelectDate = (arg) => {
		const selectedEvent = eventData.find(
			(event) =>
				dayjs(event.start).isSame(arg.start, "day") &&
				event.item.available_slots > 0
		);

		if (selectedEvent) {
			setExamSchedule(selectedEvent.item.id);
			form.setFieldsValue({ exam_schedule_id: selectedEvent.item.id });
		}
	};

	const clearSelection = () => {
		setExamSchedule("All");
		form.resetFields(["exam_schedule_id"]);
	};

	return (
		<>
			<div className="container">
				<FullCalendar
					// ref to the calendar to access the calendar object
					ref={calendarRef}
					height={
						location.pathname === "/applicants/walk-in" ? "500px" : "650px"
					}
					dayMaxEvents={true}
					displayEventTime={false}
					events={eventData}
					eventContent={(arg) => {
						const { event } = arg;
						const { title } = event._def;
						const { color } = event._def.ui;
						const { time } = event._def.extendedProps;

						return (
							<div
								className="event-content"
								style={{
									backgroundColor: color,
									borderRadius: "5px",
									padding: "5px",
									color: "white",
								}}
							>
								{title}
								<br />
								{time}
							</div>
						);
					}}
					plugins={[
						dayGridPlugin,
						timeGridPlugin,
						interactionPlugin,
						multiMonthPlugin,
					]}
					headerToolbar={{
						left: "prev,today,next",
						center: "title",
						right: "dayGridMonth,timeGridWeek,timeGridDay",
					}}
					views={{
						dayGridMonth: {},
						timeGridWeek: {
							nowIndicator: true,
							slotMinTime: "06:00:00",
							slotMaxTime: "22:00:00",
						},
						timeGridDay: {
							nowIndicator: true,
							slotMinTime: "06:00:00",
							slotMaxTime: "22:00:00",
						},
					}}
					eventDidMount={({ event }) =>
						moment(event.start).isSame(new Date(), "day") && eventCount++
					}
					datesSet={({ startStr, endStr, args }) => {
						document
							// highlight the current day, by adding a class to the header
							.querySelectorAll(".fc-col-header-cell")
							.forEach(
								(header) =>
									header.textContent === currentDay &&
									header.classList.add("current-day-header")
							);

						const start = new Date(startStr);

						document.querySelectorAll(".fc-day").forEach((cell, index) => {
							const date = new Date(
								start.getTime() + index * 24 * 60 * 60 * 1000
							);

							if (calendarRef.current) {
								const eventsOnThisDay = calendarRef.current
									.getApi()
									.getEvents()
									.filter((event) => event.start.getDate() === date.getDate());
								if (eventsOnThisDay.length > 0) {
									const badge = Object.assign(document.createElement("badge"), {
										className: "badge",
									});

									// Reset event count
									const countTextElement = cell.querySelector(".countText");
									const badgeElement = cell.querySelector(".badge");
									if (countTextElement) countTextElement.remove();
									if (badgeElement) badgeElement.remove();

									cell.append(badge);

									if (
										calendarRef.current.getApi().view.type !== "dayGridMonth"
									) {
										const countText = Object.assign(
											document.createElement("span"),
											{
												className: "countText",
												textContent: ` ${eventsOnThisDay.length} Schedule`,
											}
										);
										cell.append(countText);
									}
								}
							}
						});
					}}
					dayCellClassNames={(arg) => {
						if (dayjs(arg.date).isBefore(currentDate)) {
							return "fc-day-disabled";
						}
					}}
					selectable={true}
					selectAllow={(selectInfo) => {
						return dayjs(selectInfo.start).isSameOrAfter(currentDate);
					}}
					select={onSelectDate}
					eventClick={(arg) => {
						let item = arg.event.extendedProps.item;

						if (dayjs(item.exam_date).isBefore(currentDate)) {
							alert("This date is locked and cannot be selected.");
							return;
						}

						if (item.available_slots > 0) {
							setExamSchedule(item.id);
							form.setFieldsValue({ exam_schedule_id: item.id });
						}
					}}
				/>
				{selectExamSchedule && (
					<Button
						type="primary"
						onClick={clearSelection}
						style={{ marginTop: "10px" }}
					>
						Clear Selection
					</Button>
				)}
			</div>
		</>
	);
}
