import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Col, Form, Row, Typography } from "antd";

export default function PagePaymentConfirmation() {
	const params = useParams();
	const location = useLocation();
	const [form] = Form.useForm();

	console.log("params: ", params);
	console.log("location: ", location);
	console.log("queryParams: ", location.search);

	return (
		<Form form={form} id="PagePaymentConfirmation">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Typography.Title level={1} style={{ color: "#1c53a8" }}>
						Online Payment
					</Typography.Title>
				</Col>
			</Row>
		</Form>
	);
}
