// import { useLocation } from "react-router-dom";
import { Form, Row, Button, Col, DatePicker, Select, notification } from "antd";

import validateRules from "../../../providers/validateRules";
import { GET, POST } from "../../../providers/useAxiosQuery";
import notificationErrors from "../../../providers/notificationErrors";

export default function PageReport() {
	// const location = useLocation();
	const [form] = Form.useForm();

	const { data: dataDepartment } = GET(
		`api/department`,
		"department_dropdown",
		(res) => {},
		false
	);

	const { data: dataSemester } = GET(
		`api/semester`,
		"semester",
		(res) => {},
		false
	);

	// const { data: dataExamCategory } = GET(
	// 	`api/exam_category`,
	// 	"exam_category_list"
	// );

	// console.log("dataExamCategory:", dataExamCategory);
	const { mutate: mutateReport, isLoading: isLoadingReport } = POST(
		`api/student_exam_report_print`,
		"student_exam_report_print"
	);

	const onFinish = (values) => {
		// console.log("values:", values);
		let data = {
			...values,
			date_range: values.date_range.map((date) => date.format("YYYY-MM-DD")),
		};

		mutateReport(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Exam Report",
						description: res.message,
					});
					form.resetFields();
				} else {
					notification.error({
						message: "Exam Report",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<Form form={form} onFinish={onFinish}>
			<Row gutter={[12, 12]} style={{ justifyContent: "center" }}>
				<Col xs={24} sm={24} md={24} lg={8}>
					<Form.Item name="date_range" rules={[validateRules.required]}>
						<DatePicker.RangePicker
							className="w-100"
							format={["MM/DD/YYYY", "MM/DD/YYYY"]}
						/>
					</Form.Item>
				</Col>

				{/* <Col xs={24} sm={24} md={24} lg={8}>
					<Form.Item name="exam_category_id" rules={[validateRules.required]}>
						<Select
							size="large"
							placeholder="Category"
							allowClear
							required={true}
							options={
								dataExamCategory && dataExamCategory.data
									? dataExamCategory.data.map((item) => {
											return {
												label: item.category,
												value: item.id,
											};
									  })
									: []
							}
						/>
					</Form.Item>
				</Col> */}
			</Row>

			<Row gutter={[12, 12]} style={{ justifyContent: "center" }}>
				<Col xs={24} sm={24} md={24} lg={8}>
					<Form.Item name="department_id" rules={[validateRules.required]}>
						<Select
							size="large"
							placeholder="Department"
							required={true}
							allowClear={true}
							options={[
								{ label: "All Departments", value: "all" },
								...(dataDepartment && dataDepartment.data
									? dataDepartment.data.map((item) => ({
											label: item.department_name,
											value: item.id,
									  }))
									: []),
							]}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={[12, 12]} style={{ justifyContent: "center" }}>
				<Col xs={24} sm={24} md={24} lg={8}>
					<Form.Item name="semester_id" rules={[validateRules.required]}>
						<Select
							size="large"
							placeholder="Semester"
							allowClear
							required={true}
							options={
								dataSemester && dataSemester.data
									? dataSemester.data.map((item) => {
											return {
												label: item.semester,
												value: item.id,
											};
									  })
									: []
							}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="text-center">
						<Button
							type="primary"
							className="btn-main-primary"
							htmlType="submit"
							name="btn_submit"
							loading={isLoadingReport}
						>
							SUBMIT
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	);
}
