import { useState } from "react";
import { Row, Col, Form, notification, Card, Tabs, Button, Flex } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import { GET, POST } from "../../../providers/useAxiosQuery";
import { apiUrl, defaultProfile } from "../../../providers/companyInfo";
// import imageFileToBase64 from "../../../providers/imageFileToBase64";
import notificationErrors from "../../../providers/notificationErrors";

import ApplicantFormAccountInfo from "./PageApplicantFormComponent/ApplicantFormAccountInfo";
import ApplicantFormBasicInfo from "./PageApplicantFormComponent/ApplicantFormBasicInfo";

import ApplicantFormExamInfo from "./PageApplicantFormComponent/ApplicantFormExamInfo";
import ApplicantFormPhotoInfo from "./PageApplicantFormComponent/ApplicantFormPhotoInfo";
import ApplicantFormSchoolAttendedInfo from "./PageApplicantFormComponent/ApplicantFormSchoolAttendedInfo";
import ApplicantFormFamilyProfile from "./PageApplicantFormComponent/ApplicantFormFamilyProfile";
import ApplicantFormEmergencyContact from "./PageApplicantFormComponent/ApplicantFormEmergencyContact";
import ApplicantFormAdditionalInfo from "./PageApplicantFormComponent/ApplicantFormAdditionalInfo";
import ApplicantFormAcademicProfile from "./PageApplicantFormComponent/ApplicantFormAcademicProfile";
import ApplicantFormAddressInfo from "./PageApplicantFormComponent/ApplicantFormAddressInfo";
import ApplicantFormDisabilityInfo from "./PageApplicantFormComponent/ApplicantFormHealthInfo";
import ModalAuthPassword from "./components/ModalAuthPassword";
import ModalProfile from "../PageProfilingPortal/components/ModalProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

export default function PageApplicantFormEdit(props) {
	const { profileId, refetchStudentExam } = props;

	const params = useParams();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [formData, setFormData] = useState(null);
	const location = useLocation();

	const [scholarship, setScholarship] = useState("");
	const [qrCode, setQrCode] = useState("");
	const [selectedStudentLevel, setSelectedStudentLevel] = useState();

	const [examSchedule, setExamSchedule] = useState();

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});
	const [toggleModalProfile, setToggleModalProfile] = useState({
		open: false,
		data: null,
	});

	const [
		toggleModalUploadProfilePicture,
		setToggleModalUploadProfilePicture,
	] = useState({
		open: false,
		file: null,
		src: null,
		is_camera: null,
		fileName: null,
	});

	let profile_id = params.id ? params.id : profileId;

	const { data: dataCivilStatus } = GET(
		`api/civilstatus`,
		"civilstatus",
		(res) => {},
		false
	);

	const { data: dataNationalities } = GET(
		`api/nationality`,
		"nationality",
		(res) => {},
		false
	);

	const { data: dataReligion } = GET(
		`api/religion`,
		"religion",
		(res) => {},
		false
	);

	GET(
		`api/student_exams/${profile_id}`,
		["student_exams_info", "check_user_permission"],
		(res) => {
			if (res.data) {
				let data = res.data;

				let contact_number = null;
				if (
					data.profile_contact_informations &&
					data.profile_contact_informations.length
				) {
					let filteredData = data.profile_contact_informations.filter(
						(item) => item.status === 1
					);
					if (filteredData.length > 0) {
						contact_number = filteredData[0].contact_number;
					}
				}

				let personal_email = null;
				if (
					data.profile_contact_informations &&
					data.profile_contact_informations.length
				) {
					let filteredData = data.profile_contact_informations.filter(
						(item) => item.status === 1
					);
					if (filteredData.length > 0) {
						personal_email = filteredData[0].email;
					}
				}
				let language =
					data.profile_languages
						?.filter((item) => item.status === 1)
						.map((item) => item.language) || [];

				// Studen Exam Qr
				let qrCode = null;
				if (data.student_exam_qrs && data.student_exam_qrs.length > 0) {
					qrCode =
						data.student_exam_qrs[data.student_exam_qrs.length - 1].qr_code;
					setQrCode(qrCode);
				}

				// Health Information
				let have_disability = null;
				let disability_type = null;
				let other_disability = null;
				let have_difficulty = null;
				let difficulty_type = null;
				let other_difficulty = null;

				if (
					data.profile_health_informations &&
					data.profile_health_informations.length > 0
				) {
					have_disability = data.profile_health_informations[0].have_disability;
					disability_type = data.profile_health_informations[0].disability_type;
					other_disability =
						data.profile_health_informations[0].other_disability;
					have_difficulty = data.profile_health_informations[0].have_difficulty;
					difficulty_type = data.profile_health_informations[0].difficulty_type;
					other_difficulty =
						data.profile_health_informations[0].other_difficulty;
				}

				// Exam Information
				let student_level_id = null;
				let current_course_id = null;
				let student_strand = null;
				let first_course_id = null;
				let second_course_id = null;
				let third_course_id = null;
				let student_status = null;
				let previous_school_name = null;
				let previous_school_year = null;
				let applied_to_fsuu = null;
				let year_applied = null;
				let accepted_to_fsuu = null;
				let year_accepted = null;
				let attended_to_fsuu = null;
				let year_attended = null;

				if (data.student_academics && data.student_academics.length > 0) {
					student_level_id = data.student_academics[0].student_level_id;
					current_course_id = data.student_academics[0].current_course_id;
					student_strand = data.student_academics[0].student_strand;
					first_course_id = data.student_academics[0].first_course_id;
					second_course_id = data.student_academics[0].second_course_id;
					third_course_id = data.student_academics[0].third_course_id;
					student_status = location.pathname.includes("/profiling-portal/edit")
						? ""
						: data.student_academics[0].student_status;

					// For Transferee
					previous_school_name = data.student_academics[0].previous_school_name;
					previous_school_year = data.student_academics[0].previous_school_year
						? dayjs(data.student_academics[0].previous_school_year)
						: null;
					applied_to_fsuu = data.student_academics[0].applied_to_fsuu;
					year_applied = data.student_academics[0].year_applied
						? dayjs(data.student_academics[0].year_applied)
						: null;
					accepted_to_fsuu = data.student_academics[0].accepted_to_fsuu;
					year_accepted = data.student_academics[0].year_accepted
						? dayjs(data.student_academics[0].year_accepted)
						: null;
					attended_to_fsuu = data.student_academics[0].attended_to_fsuu;
					year_attended = data.student_academics[0].year_attended
						? dayjs(data.student_academics[0].year_attended)
						: null;
				}

				let exam_schedule_id = null;
				let exam_category_id = null;

				if (
					data.student_academics &&
					data.student_academics.length > 0 &&
					data.student_academics[0].student_exams &&
					data.student_academics[0].student_exams.length > 0
				) {
					exam_schedule_id = location.pathname.includes(
						"/profiling-portal/edit"
					)
						? ""
						: Number(
								data.student_academics[0].student_exams[0].exam_schedule_id
						  );

					setExamSchedule(exam_schedule_id);

					exam_category_id = location.pathname.includes(
						"/profiling-portal/edit"
					)
						? ""
						: Number(
								data.student_academics[0].student_exams[0].exam_category_id
						  );

					// Exam Schedule
				}

				let apply_scholarship = null;
				let scholarship_id = null;

				if (data.student_academics && data.student_academics.length > 0) {
					scholarship_id = data.student_academics[0].scholarship_id;
					apply_scholarship = data.student_academics[0].apply_scholarship;
				}

				// Profile Picture
				if (data && data.attachments && data.attachments.length > 0) {
					const profileAttachments = data.attachments.filter(
						(attachment) => attachment.file_description === "Applicant Profile"
					);

					if (profileAttachments.length > 0) {
						setToggleModalUploadProfilePicture((ps) => ({
							...ps,
							src: apiUrl(
								profileAttachments[profileAttachments.length - 1].file_path
							),
						}));
					} else {
						setToggleModalUploadProfilePicture((ps) => ({
							...ps,
							src: defaultProfile,
						}));
					}
				}

				setScholarship(scholarship_id);
				setSelectedStudentLevel(student_level_id);

				form.setFieldsValue({
					username: data.user.username,
					email: data.user.email,

					// Personal Information
					firstname: data.firstname,
					middlename: data.middlename ? data.middlename : "",
					lastname: data.lastname,
					name_ext: data.name_ext ? data.name_ext : "",
					birthplace: data.birthplace,
					birthdate: data.birthdate ? dayjs(data.birthdate) : null,
					age: data.age,
					gender: data.gender,
					language: language,
					blood_type: data.blood_type ? data.blood_type : "",
					height: data.height ? data.height : "",
					weight: data.weight ? data.weight : "",
					religion_id: data.religion_id ? data.religion_id : "",
					nationality_id: data.nationality_id ? data.nationality_id : "",
					civil_status_id: data.civil_status_id ? data.civil_status_id : "",
					contact_number,
					personal_email,

					// Health Information
					have_disability,
					disability_type,
					other_disability,
					have_difficulty,
					difficulty_type,
					other_difficulty,

					// Exam Information
					student_level_id: student_level_id ? student_level_id : "",
					current_course_id: current_course_id ? current_course_id : "",
					student_strand: student_strand ? student_strand : "",
					exam_schedule_id: exam_schedule_id ? exam_schedule_id : "",
					exam_category_id: exam_category_id ? exam_category_id : "",
					scholarship_id: scholarship_id ? scholarship_id : "",
					apply_scholarship: apply_scholarship ? apply_scholarship : "",

					student_status,
					first_course_id: first_course_id ? first_course_id : "",
					second_course_id: second_course_id ? second_course_id : "",
					third_course_id: third_course_id ? third_course_id : "",

					previous_school_name: previous_school_name
						? previous_school_name
						: "",
					previous_school_year: previous_school_year
						? previous_school_year
						: "",
					applied_to_fsuu: applied_to_fsuu ? applied_to_fsuu : "",
					year_applied: year_applied ? year_applied : "",
					accepted_to_fsuu: accepted_to_fsuu ? accepted_to_fsuu : "",
					year_accepted: year_accepted ? year_accepted : "",
					attended_to_fsuu: attended_to_fsuu ? attended_to_fsuu : "",
					year_attended: year_attended ? year_attended : "",
				});
			}
		}
	);

	const {
		mutate: mutateApplicantUpdate,
		isLoading: isLoadingUpdateChanges,
	} = POST(`api/update_student_basic_info`, `student_exams_list`);

	const onFinish2 = (auth_password) => {
		let values = formData;
		let data = new FormData();

		let username;
		let data2;

		if (location.pathname.includes("/profiling-portal/edit")) {
			username = auth_password.username;

			// Process date values
			let previous_school_year = dayjs(
				auth_password.previous_school_year,
				"YYYY"
			).format("YYYY");
			let year_applied = auth_password.year_applied
				? dayjs(auth_password.year_applied, "YYYY").format("YYYY")
				: null;
			let year_accepted = auth_password.year_accepted
				? dayjs(auth_password.year_accepted, "YYYY").format("YYYY")
				: null;
			let year_attended = auth_password.year_attended
				? dayjs(auth_password.year_attended, "YYYY").format("YYYY")
				: null;

			// Prepare the JSON data
			data2 = {
				...auth_password,
				previous_school_year: previous_school_year,
				year_applied: year_applied,
				year_accepted: year_accepted,
				year_attended: year_attended,
				profile_id: parseInt(profile_id, 10),
				auth_password: auth_password,
			};
		} else {
			username = values.username;

			let previous_school_year = dayjs(
				values.previous_school_year,
				"YYYY"
			).format("YYYY");
			let year_applied = values.year_applied
				? dayjs(values.year_applied, "YYYY").format("YYYY")
				: null;
			let year_accepted = values.year_accepted
				? dayjs(values.year_accepted, "YYYY").format("YYYY")
				: null;
			let year_attended = values.year_attended
				? dayjs(values.year_attended, "YYYY").format("YYYY")
				: null;

			data2 = {
				...values,
				previous_school_year: previous_school_year,
				year_applied: year_applied,
				year_accepted: year_accepted,
				year_attended: year_attended,
				profile_id: parseInt(profile_id, 10),
				auth_password: auth_password,
			};
		}

		// Append profile picture if it exists
		if (toggleModalUploadProfilePicture.file) {
			data.append("profile_picture", toggleModalUploadProfilePicture.file);
		}

		// Append ID
		data.append("id", profile_id);

		// Append data2 properties to the FormData object `data`
		for (const key in data2) {
			if (data2.hasOwnProperty(key)) {
				data.append(key, data2[key]);
			}
		}

		mutateApplicantUpdate(data, {
			onSuccess: (res) => {
				if (res.success) {
					setToggleModalAuthPassword({
						open: false,
						data: null,
					});
					notification.success({
						message: "Applicant Basic Information Updated",
						description: res.message,
					});

					if (location.pathname.includes("/profiling-portal/edit")) {
						setToggleModalProfile({
							open: true,
							data: null,
							username: username,
							qrCode: qrCode,
						});
					}

					if (location.pathname.includes("/profile-validation")) {
						// Refetch the table data
						refetchStudentExam();
					}
				} else {
					notification.error({
						message: "Applicant Schedule Failed",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	// To filter scholarship offered base on student level
	const handleStudentLevelChange = (value) => {
		setSelectedStudentLevel(parseInt(value, 10));
	};

	return (
		<>
			<Row gutter={[12, 12]} className="m-0">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="p-0">
					<Form
						form={form}
						id={
							location.pathname.includes("/applicants/walk-in")
								? ""
								: "PageProfilingPortalEdit"
						}
						scrollToFirstError={{
							behavior: "smooth",
							block: "center",
						}}
						onFinish={
							location.pathname.includes("/profiling-portal/edit")
								? onFinish2
								: null
						}
					>
						<Row gutter={[12, 12]}>
							{/* Privacy Statement */}
							<Col xs={24} sm={24} md={24} lg={12} xl={12} className="m-0">
								{location.pathname.includes("/profiling-portal/edit") && (
									<Flex align="flex-start" vertical>
										<img
											width="400px"
											src="./../../assets/images/fsuu_logo_v3.png"
											alt="fsuu-logo"
										/>

										<Col xs={24} sm={24} md={24} lg={12} xl={12}>
											<p
												style={{
													width: "320px",
													fontSize: "12px",
													textAlign: "justify",
													color: "#000",
													padding: "10px 20px",
													background: "rgba(90, 90, 90, 0.25)",
												}}
											>
												<strong>Privacy Statement: </strong>
												<em>
													The data provided by the users/applicants is solely
													used for basic profiling and will not be used for any
													malicious purposes.
												</em>
											</p>
										</Col>
									</Flex>
								)}

								<Card
									title="Account Information"
									className="card-main-primary account-information"
								>
									<ApplicantFormAccountInfo
										action="edit"
										form={form}
										formDisabled={true}
									/>
								</Card>
							</Col>

							{/* Photo */}
							<Col xs={24} sm={12} md={12} lg={12} xl={12}>
								<Card
									title="Profile Photo"
									className="card-main-primary card-profile-photo"
								>
									<ApplicantFormPhotoInfo
										action="add"
										form={form}
										toggleModalUploadProfilePicture={
											toggleModalUploadProfilePicture
										}
										setToggleModalUploadProfilePicture={
											setToggleModalUploadProfilePicture
										}
									/>
								</Card>
							</Col>

							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Card
									title="Personal Information"
									className="card-main-primary"
								>
									<ApplicantFormBasicInfo
										action="edit"
										form={form}
										formDisabled={true}
										dataCivilStatus={
											dataCivilStatus && dataCivilStatus.data
												? dataCivilStatus.data
												: []
										}
										dataNationalities={
											dataNationalities && dataNationalities.data
												? dataNationalities.data
												: []
										}
										dataReligion={
											dataReligion && dataReligion.data ? dataReligion.data : []
										}
									/>
									{!location.pathname.includes("/applicants") && (
										<ApplicantFormAddressInfo
											action="edit"
											form={form}
											profile_id={params.id ? params.id : profileId}
										/>
									)}
								</Card>
							</Col>

							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Card
									title="Health Information"
									className="card-main-primary disability-info"
								>
									<ApplicantFormDisabilityInfo form={form} />
								</Card>
							</Col>

							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Card
									title="Entrance Exam Information"
									className="card-main-primary"
								>
									<ApplicantFormExamInfo
										form={form}
										formDisabled={true}
										scholarship={scholarship}
										handleStudentLevelChange={handleStudentLevelChange}
										selectedStudentLevel={selectedStudentLevel}
										examSchedule={examSchedule}
										setExamSchedule={setExamSchedule}
									/>
								</Card>
							</Col>
						</Row>

						<Row gutter={[12, 12]} className="mt-12">
							{location.pathname.includes("/profiling-portal/edit") ? (
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Flex justify="space-between">
										<Button
											variant="outlined"
											size="large"
											onClick={() => navigate("/profiling-portal")}
										>
											<FontAwesomeIcon icon={faArrowLeft} />
											&nbsp;Profiling Portal
										</Button>

										<Button
											type="primary"
											htmlType="submit"
											className="btn-main-primary w-xs-200"
											name="btn_submit"
											size="large"
										>
											Update Information
										</Button>
									</Flex>
								</Col>
							) : (
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Flex justify="flex-end">
										<Button
											type="primary"
											className="btn-main-primary mt-20 mb-20 w-xs-200"
											onClick={() => {
												setFormData(form.getFieldsValue());
												const values = form.getFieldsValue();
												setToggleModalAuthPassword({
													open: true,
													data: values,
												});
											}}
											name="btn_submit"
										>
											Update Changes
										</Button>
									</Flex>
								</Col>
							)}
						</Row>

						{/* Modal */}
						<ModalAuthPassword
							toggleModalAuthPassword={toggleModalAuthPassword}
							setToggleModalAuthPassword={setToggleModalAuthPassword}
							onFinish2={onFinish2}
							isLoadingUpdateChanges={isLoadingUpdateChanges}
						/>

						<ModalProfile
							toggleModalProfile={toggleModalProfile}
							setToggleModalProfile={setToggleModalProfile}
						/>
					</Form>
				</Col>

				{location.pathname.includes("/applicants/walk-in/edit") && (
					<Col
						sm={24}
						md={24}
						lg={24}
						xl={24}
						xxl={24}
						className={
							location.pathname.includes("/profiling-portal/edit") ||
							location.pathname.includes("/profiling-portal/add")
								? "p-20"
								: "p-0"
						}
					>
						<Tabs
							className="TABS"
							type="card"
							defaultActiveKey="1"
							items={[
								{
									label: "Student Address",
									key: "1",
									children: (
										<ApplicantFormAddressInfo
											action="edit"
											form={form}
											profile_id={profile_id}
										/>
									),
								},
								{
									label: "School Attended",
									className: "tab-item-school-attended-info",
									key: "2",
									children: (
										<ApplicantFormSchoolAttendedInfo
											action="edit"
											form={form}
											profile_id={profile_id}
											handleStudentLevelChange={handleStudentLevelChange}
											selectedStudentLevel={selectedStudentLevel}
										/>
									),
								},
								{
									label: "Family Profile",
									key: "3",
									children: (
										<ApplicantFormFamilyProfile
											action="edit"
											profile_id={profile_id}
										/>
									),
								},
								{
									label: "Emergency Contact",
									key: "4",
									children: (
										<ApplicantFormEmergencyContact
											action="edit"
											profile_id={profile_id}
										/>
									),
								},
								{
									label: "Academic Profile",
									key: "5",
									children: (
										<ApplicantFormAcademicProfile
											action="edit"
											profile_id={profile_id}
										/>
									),
								},
								{
									label: "Additional Information",
									key: "6",
									children: (
										<ApplicantFormAdditionalInfo
											action="edit"
											profile_id={profile_id}
										/>
									),
								},
							]}
						/>
					</Col>
				)}
			</Row>
		</>
	);
}
