export default function generateRandomString(length) {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	const unixTime = Math.floor(Date.now() / 1000).toString();
	let result = "";

	for (let i = 0; i < length; i++) {
		const randomIndex =
			(parseInt(unixTime.charAt(i % unixTime.length)) +
				Math.floor(Math.random() * charactersLength)) %
			charactersLength;
		result += characters.charAt(randomIndex);
	}

	// Merge result and unixTime
	let mergedString = result + unixTime;

	// Shuffle the merged string
	let shuffledString = mergedString
		.split("")
		.sort(() => 0.5 - Math.random())
		.join("");

	return shuffledString;
}
