import { useEffect, useState } from "react";
import { Modal, Button, Form } from "antd";

import PageApplicantFormEdit from "../../PageApplicants/PageApplicantFormEdit";
import ModalFormValidation from "./ModalFormValidation";

export default function ModalFormProfile(props) {
	const {
		toggleModalFormProfile,
		setToggleModalFormProfile,
		refetchStudentExam,
	} = props;

	const [form] = Form.useForm();
	const [toggleModalFormValidation, setToggleModalFormValidation] = useState({
		open: false,
		data: null,
	});

	useEffect(() => {
		if (toggleModalFormProfile.open) {
			form.setFieldsValue({
				...toggleModalFormProfile.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormProfile]);

	return (
		<Modal
			width={1200}
			className="modal-profile-validation"
			// title={`VALIDATE APPLICANT | ${toggleModalFormProfile.data &&
			// 	toggleModalFormProfile.data.contact_fullname}`}
			title="VALIDATE APPLICANT"
			open={toggleModalFormProfile.open}
			onCancel={() => {
				setToggleModalFormProfile({
					open: false,
					data: null,
				});
				form.resetFields();
			}}
			forceRender
			footer={[
				<Button
					className="btn-main-primary outlined"
					key="cancel"
					onClick={() => {
						setToggleModalFormProfile({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CLOSE
				</Button>,
				<Button
					type="primary"
					className="btn-main-primary outlined"
					key="validate"
					onClick={() => {
						setToggleModalFormValidation({
							open: true,
							data: toggleModalFormProfile && toggleModalFormProfile.data,
						});
						form.resetFields();
					}}
				>
					VERIFY
				</Button>,
			]}
		>
			<Form form={form}>
				<PageApplicantFormEdit
					profileId={
						toggleModalFormProfile.data &&
						toggleModalFormProfile.data.profile_id
					}
					refetchStudentExam={refetchStudentExam}
				/>
			</Form>

			<ModalFormValidation
				toggleModalFormValidation={toggleModalFormValidation}
				setToggleModalFormValidation={setToggleModalFormValidation}
				setToggleModalFormProfile={setToggleModalFormProfile}
				refetchStudentExam={refetchStudentExam}
			/>
		</Modal>
	);
}
