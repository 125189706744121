import { useCallback, useEffect, useRef, useState } from "react";
import {
	Modal,
	Button,
	Form,
	notification,
	Flex,
	Row,
	Col,
	Upload,
	Divider,
	Empty,
} from "antd";
import { useParams } from "react-router-dom";
import { defaultDocument } from "../../../../providers/companyInfo";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCamera,
	faMoneyCheckDollar,
	faRefresh,
	faUpload,
} from "@fortawesome/pro-regular-svg-icons";

import Webcam from "react-webcam";
import dayjs from "dayjs";
import dataURLtoBlob from "../../../../providers/dataURLtoBlob";
import imageFileToBase64 from "../../../../providers/imageFileToBase64";

import FloatInput from "../../../../providers/FloatInput";
import notificationErrors from "../../../../providers/notificationErrors";

export default function ModalPaymentOTC(props) {
	const { toggleModalPaymentOTC, setToggleModalPaymentOTC } = props;

	const params = useParams();
	const [form] = Form.useForm();
	const [applicantData, setApplicantData] = useState({
		student_academic_id: "",
		fullname: "",
		exam_category: "",
		exam_date: "",
		exam_time: "",
		exam_fee: "",
		exam_schedule_id: "",
		exam_category_id: "",
	});

	const { data: dataStudentExamQr } = GET(
		`api/student_exams`,
		"exam_schedule_list",
		(res) => {},
		false
	);

	useEffect(() => {
		if (dataStudentExamQr && dataStudentExamQr.data) {
			const matchedItem = dataStudentExamQr.data.find(
				(item) => item.qr_code === params.qrcode && item.status === "Active"
			);

			if (matchedItem) {
				setApplicantData({
					student_academic_id: matchedItem.student_academic_id,
					fullname: matchedItem.fullname,
					exam_category: matchedItem.exam_category,
					exam_date: matchedItem.exam_date,
					exam_time: matchedItem.exam_time,
					exam_fee: matchedItem.exam_fee,
					exam_schedule_id: matchedItem.exam_schedule_id,
					exam_category_id: matchedItem.exam_category_id,
				});
			}
		}
	}, [dataStudentExamQr, params.qrcode]);

	const { mutate: mutateApplicantOr } = POST(
		`api/applicant_official_receipt`,
		"student_exams_list"
	);

	const onFinish = (values) => {
		if (!fileImage.file) {
			notification.error({
				message: "Upload Official Receipt",
				description: "Please upload your official receipt!",
			});
			return;
		}

		let data = new FormData();

		data.append("student_academic_id", applicantData.student_academic_id);
		data.append("or_number", values.or_number);
		data.append("file_path", fileImage.file);
		data.append("exam_schedule_id", applicantData.exam_schedule_id);
		data.append("exam_category_id", applicantData.exam_category_id);

		mutateApplicantOr(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Applicant Official Receipt",
						description: res.message,
					});

					form.resetFields();
					setFileImage({
						is_camera: false,
						status: null,
						file: null,
						src: null,
						isCapture: false,
						fileName: null,
					});
					setToggleModalPaymentOTC({
						open: false,
						data: null,
					});
				} else {
					notification.error({
						message: "Applicant Official Receipt",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const webcamRef = useRef(null);
	const [fileImage, setFileImage] = useState({
		is_camera: false,
		status: null,
		isCapture: false,
		file: null,
		src: null,
		fileName: null,
	});

	const propsUpload = {
		action: false,
		accept: ".jpg,.png",
		maxCount: 1,
		beforeUpload: (file) => {
			let error = false;
			const isJPG = file.type === "image/jpeg" || file.type === "image/png";
			const isLt2M = file.size / 1024 / 1024 < 2;

			if (!isJPG) {
				notification.error({
					message: "Upload Official Receipt",
					description: "You can only upload JPG/PNG file!",
				});
				error = Upload.LIST_IGNORE;
			}

			if (!isLt2M) {
				notification.error({
					message: "Upload Official Receipt",
					description: "Image must be smaller than 2MB!",
				});
				error = Upload.LIST_IGNORE;
			}

			if (error === false) {
				imageFileToBase64(file, (imageUrl) =>
					setFileImage((ps) => ({
						...ps,
						src: imageUrl,
						file: file,
						fileName: file.name,
					}))
				);
			}

			return error;
		},
		showUploadList: false,
	};

	const handleOpenCamera = () => {
		navigator.mediaDevices
			.getUserMedia({
				audio: false,
				video: true,
			})
			.then(function(stream) {
				if (stream.getVideoTracks().length > 0) {
					setFileImage((ps) => ({
						...ps,
						is_camera: true,
						status: "available",
						message: "Camera detected...",
					}));
				} else {
					setFileImage((ps) => ({
						...ps,
						is_camera: true,
						status: "unavailable",
						message: "Camera not detected...",
					}));
				}
			})
			.catch(function(error) {
				setFileImage((ps) => ({
					...ps,
					is_camera: true,
					status: "error",
					message: error.message,
				}));
			});
	};

	const handleCapture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		const blob = dataURLtoBlob(imageSrc);

		setFileImage((ps) => ({
			...ps,
			src: imageSrc,
			file: blob,
			isCapture: true,
			fileName: blob.size + "-camera.png",
		}));
	}, [webcamRef]);

	const handleRenderCamera = () => {
		if (fileImage.isCapture) {
			return (
				<img
					alt=""
					src={fileImage.src ? fileImage.src : defaultDocument}
					className="w-100"
				/>
			);
		} else {
			if (fileImage.status === "available") {
				return (
					<Webcam
						ref={webcamRef}
						style={{ maxWidth: "100%" }}
						disablePictureInPicture={true}
						screenshotFormat="image/jpeg"
						videoConstraints={{
							width: 1280,
							height: 720,
							facingMode: "user",
						}}
					/>
				);
			} else {
				return (
					<div
						style={{
							display: "flex",
							width: "100%",
							padding: "100px 0px",
							justifyContent: "center",
							alignItems: "center",
							background: "#000",
							color: "#fff",
							flexDirection: "column",
							gap: "12px 12px",
						}}
					>
						<div>
							{fileImage.message}
							<br />
							{fileImage.message === "Requested device not found"
								? "Please check your camera and try refresh the page."
								: "Please allow camera access and try refresh the page."}
						</div>
					</div>
				);
			}
		}
	};

	return (
		<Modal
			title={
				<Flex align="center" gap={10}>
					<FontAwesomeIcon icon={faMoneyCheckDollar} /> OVER-THE-COUNTER (OTC)
					PAYMENT
				</Flex>
			}
			className="modal-otc-payment"
			open={toggleModalPaymentOTC.open}
			onCancel={() => {
				setToggleModalPaymentOTC({
					open: false,
					data: null,
				});
				form.resetFields();
			}}
			forceRender
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalPaymentOTC({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					key={2}
					onClick={() => {
						form.submit();
					}}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Flex justify="center">
					<Row gutter={[12, 12]}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Flex gap={15}>
								<div style={{ fontSize: "18px", width: "100px" }}>
									Full Name
									<br />
									Exam
									<br />
									Date
									<br />
									Time
									<br />
									Amount
									<br />
									<br />
								</div>
								<div
									style={{
										fontSize: "18px",
										marginTop: "0px",
										fontWeight: "600",
										width: "100%",
									}}
								>
									{applicantData.fullname}
									<br />
									{applicantData.exam_category}
									<br />
									{dayjs(applicantData.exam_date).format("MMMM DD, YYYY")}
									<br />
									{applicantData.exam_time}
									<br />
									{`PHP ${applicantData.exam_fee}.00`}
									<br />
									<br />
									<Form.Item
										name="or_number"
										rules={[
											{ required: true, message: "OR Number is required" },
										]}
									>
										<FloatInput
											label="OR Number"
											placeholder="OR Number"
											required
										/>
									</Form.Item>
								</div>
							</Flex>
						</Col>

						<Divider />

						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							{!fileImage.is_camera ? (
								<div className="image-container">
									{fileImage.src ? (
										<img alt="" src={fileImage.src} />
									) : (
										<Empty
											image={Empty.PRESENTED_IMAGE_SIMPLE}
											description="No File Attached"
										/>
									)}
								</div>
							) : (
								handleRenderCamera()
							)}
						</Col>

						<Col
							xs={24}
							sm={24}
							md={24}
							lg={24}
							xl={24}
							className="text-center mt-10"
						>
							{!fileImage.is_camera ? (
								<Upload {...propsUpload}>
									<Button icon={<FontAwesomeIcon icon={faUpload} />}>
										Upload Picture
									</Button>
								</Upload>
							) : (
								<Button
									icon={<FontAwesomeIcon icon={faUpload} />}
									onClick={() =>
										setFileImage({
											is_camera: false,
											status: null,
											file: null,
											src: null,
											isCapture: false,
										})
									}
								>
									Click to Upload
								</Button>
							)}

							{fileImage.is_camera ? (
								fileImage.status === "available" ? (
									fileImage.isCapture ? (
										<Button
											icon={<FontAwesomeIcon icon={faCamera} />}
											className="ml-10"
											onClick={() =>
												setFileImage((ps) => ({
													...ps,
													src: null,
													file: null,
													isCapture: false,
												}))
											}
										>
											Reset
										</Button>
									) : (
										<Button
											icon={<FontAwesomeIcon icon={faCamera} />}
											onClick={handleCapture}
											className="ml-10"
										>
											Capture
										</Button>
									)
								) : (
									<Button
										icon={<FontAwesomeIcon icon={faRefresh} />}
										onClick={() => window.location.reload()}
										className="ml-10"
									>
										Refresh
									</Button>
								)
							) : (
								<Button
									icon={<FontAwesomeIcon icon={faCamera} />}
									onClick={handleOpenCamera}
									className="ml-10"
								>
									Click to Open Camera
								</Button>
							)}
						</Col>
					</Row>
				</Flex>
			</Form>
		</Modal>
	);
}
